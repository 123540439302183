import { IRelashionship } from '../../data/protocols/services/relashionship/relashionship';
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/member-ordering */
import { StorageHelper } from "../../utils/functions/storage/storage";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";
// import { ILoadLegacyExtract } from "data/protocols/services/extract/legacy-extract";

export class HttpRelashionship implements IRelashionship {
  constructor(
    private readonly httpClient: HttpClient<
    IRelashionship.Params,
    IRelashionship.Result
    >,
    private readonly storageHelper: StorageHelper
  ) {}

  private headerSubscriptionKey = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${this.storageHelper.get("token")}`,
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
    ["X-Session-Id"]: this.storageHelper.get("sessionKey"),

    cutOffDate: process.env.REACT_APP_LEGACYDATE,
  };

  async getRelashionship(
    params?: IRelashionship.Params
  ): Promise<IRelashionship.RowsAndTotal> {
    const result = await this.httpClient.request({
      headers: this.headerSubscriptionKey,
      method: HttpMethods.GET,
       url: `${process.env.REACT_APP_BASE_URL_API}participant/participants/document-associated-cpf/${params?.data?.documentNumber}`,
    });
    return {
      message : result?.response?.message,
      rows: result?.response?.data?.rows,
      total: result?.response?.data?.total,
    } as IRelashionship.RowsAndTotal;
  }
}
