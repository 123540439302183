/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/member-ordering */
import { StorageHelper } from "../../utils/functions/storage/storage";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";
import { IGetAllHeadquarters } from "../../data/protocols/services/provider/get-all";

const storageHelper = new StorageHelper()
export class HttpGetAllProvidersList implements IGetAllHeadquarters {
  constructor(
    private readonly httpClient: HttpClient<IGetAllHeadquarters.Params, IGetAllHeadquarters.HttpResponse>
  ) { }

  private headerSubscriptionKey = {
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
    ["X-Session-Id"]: storageHelper.get("sessionKey"),    
  };

  async getAll(): Promise<IGetAllHeadquarters.Result> {
    // eslint-disable-next-line no-debugger
    
    const result = await this.httpClient.request({
      headers: this.headerSubscriptionKey,
      method: HttpMethods.GET,
      url: `${process.env.REACT_APP_BASE_URL_API}hotsite/distribuitor/matrizes`
    });
    return {
      response: result?.response.data.rows,
      statusCode: result?.statusCode,
      success: result?.success
    } as IGetAllHeadquarters.Result
  }
}