/* eslint-disable no-debugger */
import { FC, Fragment, useState, useEffect } from "react";
import { useTitle } from "hooks";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { Http } from "api";
import { RootState } from "store";
import { StorageHelper } from "utils/functions/storage/storage";
import { RegisterNFPageStyled } from "./styled";
import { ContainerPage, TitlePage } from "design-system/components";
import { BARCODE_LENGTH, BARCODE_MASK } from "utils/consts/barcode";
import { HttpInvoiceRegister } from "services/invoices/register";
import { useNavigate } from "react-router-dom";
import { HttpGetDocumentType } from "services/documentType/DocumentType";
import { SelectSearchItem } from "design-system/domain";
import { IGetDocumentType } from "data/protocols/services/documentType/get-documentType";
import { NFPage } from "../NFPage";
import { ToastContainer, toast } from "react-toastify";
import ModalLogout from "modal/modal-logout";


type ModalStatus = {
  error: boolean
  message: null | JSX.Element
  show: boolean
}
const http = new Http();
const httpInvoiceRegister = new HttpInvoiceRegister(http, new StorageHelper());
const httpGetDocumentType = new HttpGetDocumentType(http, new StorageHelper());
const RegisterNFPage: FC = () => {
  const { user } = useSelector((state: RootState) => state);
  const [displayBarcodeField, setDisplayBarcodeField] =
    useState<boolean>(false);
  const [barcodeValue, setBarcodeValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModalToast, setShowModalToast] = useState<ModalStatus>({
    error: false,
    message: <Fragment>
      Nota Fiscal foi registrada. Por favor, aguarde o processamento.
      <br />
      Caso queira saber o status, entrar em contato com a Central de Atendimento.
    </Fragment>,
    show: false,
  })
  const [document, setDocument] = useState<string>("")
  const [cnpjs, setCnpjs] = useState<IGetDocumentType.IDocumentType>()
  const [showModalLogout, setShowModalLogout] = useState<boolean>(false)

  const navigate = useNavigate();

  useState<boolean>(false);
  useTitle("Cadastro de Notas Fiscais");

  useEffect(() => {
    getDocumentTypeParticipant();
    if (!user.is_logged) {
      navigate("/programa");
    }
  }, []);

  const getDocumentTypeParticipant = async () => {
    const result = await httpGetDocumentType.getDocumentLink(
      user.document_number as string,
      2
    );

    if (result?.statusCode === 401) {
      setShowModalLogout(true)
      return;
    }

    if (result?.success) {
      result?.response?.rows?.unshift(user.document_number as string)
      setCnpjs(result.response);
    }
  };

  const sendInvoice = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    e.currentTarget.disabled = true;
    if (user.is_logged) {
      try {
        const response = await httpInvoiceRegister.create({
          data: {
            customer: document as string,
            documentParticipant: user.document_number as string,
            invoices: [barcodeValue],
          },
        });

        if (response.statusCode === 401) {
          setShowModalLogout(true)
        } else if (response.success) {
          toast.success("Nota enviada com sucesso", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          setShowModalToast({
            error: true,
            message: <Fragment>Nota Fiscal já cadastrada anteriormente.</Fragment>,
            show: true,
          })
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          setShowModalLogout(true)
        } else {
          toast.error(
            "Ocorreram problemas ao enviar a nota fiscal.Tente novamente em instantes.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
      } finally {
        setLoading(false);
        setBarcodeValue("");
      }
    }
  };

  useEffect(() => {
    if (showModalToast.show) {
      setTimeout(() => {
        setShowModalToast({
          ...showModalToast,
          show: false
        })
      }, 5000)
    }
  }, [showModalToast.show])

  return (
    <Fragment>
      <ModalLogout isOpen={showModalLogout}/>
      <ContainerPage>
        <TitlePage title="Cadastrar Notas Fiscais" />
        <RegisterNFPageStyled.SubTitleText>
          Caso sua revenda ou cooperativa seja participante do AGREGA e não apareça na lista abaixo, fique tranquilo, seus pontos estão sendo computados automaticamente.
        </RegisterNFPageStyled.SubTitleText>
        <RegisterNFPageStyled.ConatinerView>
          {showModalToast.show && !showModalToast.error ? <RegisterNFPageStyled.MessageNotificationSuccess>{showModalToast.message}</RegisterNFPageStyled.MessageNotificationSuccess> : <Fragment />}
          {showModalToast.show && showModalToast.error ? <RegisterNFPageStyled.MessageNotificationError>{showModalToast.message}</RegisterNFPageStyled.MessageNotificationError> : <Fragment />}
          <RegisterNFPageStyled.SelectStyled
            placeholder="Selecione seu Canal de Distribuição"
            options={[
              {
                label: "Terrena",
                value: "Terrena",
              },
            ]}
            onChange={() => setDisplayBarcodeField(true)}
          />

          {
            <Fragment>
              {displayBarcodeField && (
                <RegisterNFPageStyled.SelectWrap>
                  <RegisterNFPageStyled.SelectStyled
                    placeholder="Selecione o CPF ou CNPJ. Caso seu CNPJ não esteja na lista fale com a central de atendimento"
                    value={document || null}
                    onChange={(event) => setDocument(event as string)}
                    options={
                      cnpjs?.rows?.map((c, key: number) => ({
                        key,
                        label: c,
                        value: c,
                      })) as SelectSearchItem[]
                    }
                  />
                  <RegisterNFPageStyled.BarcodeInstruction>
                    Caso seu CNPJ não esteja na lista fale com a central de
                    atendimento.
                  </RegisterNFPageStyled.BarcodeInstruction>

                  <RegisterNFPageStyled.BarcodeMaskedInput
                    placeholder="Insira a chave de acesso da sua nota fiscal"
                    value={barcodeValue}
                    onChange={(e) => setBarcodeValue(e.target.value)}
                    mask={BARCODE_MASK}
                    guide={false}
                  />
                  <RegisterNFPageStyled.BarcodeInstruction>
                    Serão os 44 números próximos ao código de barras
                  </RegisterNFPageStyled.BarcodeInstruction>
                </RegisterNFPageStyled.SelectWrap>
              )}

              <RegisterNFPageStyled.BoxBarcodeAndButton>
                <RegisterNFPageStyled.ButtonSaveBarCode
                  disabled={
                    barcodeValue.replace(/ /g, "").length !== BARCODE_LENGTH ||
                    document == ""
                  }
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => sendInvoice(e)}
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Cadastrar</span>
                    <span style={{ marginLeft: "0.875rem" }}>
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                        visible={loading}
                      />
                    </span>
                  </div>
                </RegisterNFPageStyled.ButtonSaveBarCode>
              </RegisterNFPageStyled.BoxBarcodeAndButton>
            </Fragment>
          }
          <RegisterNFPageStyled.MessageSearcYourNF>
            <p>
              Suas Notas Fiscais poderão ser processadas em até 35 dias. Após esse prazo, o status do processamento poderá ser consultado.
              Para ajuda, acesse: <a href={`${process.env.REACT_APP_CONECTA_AG_URL}/contato`} target="_self">aqui</a>.
            </p>
          </RegisterNFPageStyled.MessageSearcYourNF>
        </RegisterNFPageStyled.ConatinerView>
        <NFPage />
      </ContainerPage>
    </Fragment>
  );
};

export default RegisterNFPage;
