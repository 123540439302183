import styled from 'styled-components'
import { colors } from "../design-system/theme/colors";


export const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;

    button {
        border-radius: 25px;
        cursor: pointer;
        height: 35px;
        margin-top: 25px;
        width: 45%;
    }

    .see-now {
        background: ${colors.primary.red};
        border: none;
        color: ${colors.generics.white};
    }

    .see-later {
        background: transparent;
        border: 1px solid ${colors.primary.red};
        color: ${colors.primary.red};
    }
`


export const ModalContent = styled.div`
    margin-top: 10px;

    .modal-content-text {
        color: ${colors.neutral.gray10};
        font-size: 14px;
    }

    .talk-us-attendance {
        color: ${colors.primary.red};
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
    }
`