import styled from "styled-components";
import { colors } from '../../../design-system/theme/colors'
import { ContainerStyledProps, TitleStyledProps } from '../../../design-system/domain/components'


const defineModalWidth = (customSize: ContainerStyledProps) => {
    switch (customSize?.size) {
        case 'SMALL':
            return '400px'
        case 'MEDIUM':
            return '800px'
        case 'BIG':
            return '1200px'
        default:
            return '300px'
    }
}

const PageDisabled = styled.div`
    width: 100%;
    height: 100% ;
    align-items: center;
    bottom: 0;
    display: flex;
    z-index: 9991;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    left: 0;
    position: fixed;
    justify-content: center;
    right: 0;
    top: 0;
    background: rgba(0,0,0, .3)
`
const Close = styled.a`
    position: absolute ;
    right: 20px;
    top: 20px;
    font-size: 30px;
`

const Container = styled.div<ContainerStyledProps>`
    max-width: 80%;
    background: ${colors.generics.white};
    border: 1px solid  ${colors.generics.grayLight};;
    border-radius: 5px;
    left: 50%;    
    padding: ${(props) => props.style?.padding ? props.style?.padding : '25px'};
    position: fixed;
    z-index: ${(props) => props?.zindex ?? 9999};
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => defineModalWidth(props)}; 
    -webkit-box-shadow: ${(props) => props?.boxShadow ?? "0px 0px 10px 0px rgba(0,0,0,0.4)"};
    -moz-box-shadow: ${(props) => props?.boxShadow ?? "0px 0px 10px 0px rgba(0,0,0,0.4)"};
    box-shadow: ${(props) => props?.boxShadow ?? "0px 0px 10px 0px rgba(0,0,0,0.4)"};
`
const Title = styled.h1<TitleStyledProps>`
    color: ${colors.primary.orange02};
    text-align: ${(props) => props?.titleAlign ? props?.titleAlign : 'center'};
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0;
    `

export const ModalStyled = {
    Close,
    Container,
    PageDisabled,
    Title,
}