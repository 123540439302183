import styled from "styled-components";
import { colors, breakpoints } from "design-system/theme";
import {
	CustomAutoComplete,
	Input,
	Checkbox,
	Button,
} from "design-system/components";

const ContainerForm = styled.main`
	background-color: ${colors.generics.white};
	display: block;
	height: 100%;
	margin: 0 auto;
	max-height: 100%;
	max-width: 100%;
	padding: 0 2%;
	width: 90%;

	${breakpoints.up("lg")} {
		padding: 0 150px 50px;
		margin: 0 auto;
	}

	${breakpoints.down("md")} {
		padding: 0 10px 20px;
		margin: unset;
	}

	${breakpoints.down("sm")} {
		padding: 0 0.5%;
		width: 96%;
	}
`;

const ContainerCulture = styled.section`
	align-items: center;
	align-self: center;
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	justify-content: start;
	width: 65%;

	${breakpoints.down("sm")} {
		align-self: start;
		align-items: start;
		gap: 0;
		width: 100%;
	}
`;

const ContainerCultureRow = styled(ContainerCulture)`
	justify-content: start;
	flex-wrap: nowrap;
	margin-top: -25px;
	width: 100%;

	${breakpoints.down("sm")} {
		flex-wrap: wrap;
		gap: 0;
	}
`;

const ContainerCheckbox = styled.section`
	align-self: center;
	align-items: center;
	display: flex;
	justify-content: start;
	margin: 30px 0 20px 0;

	${breakpoints.down("sm")} {
		width: 100%;
		gap: 8px;
	}
`;

const NewContainerCultureRow = styled(ContainerCulture)`
	justify-content: start;
	align-items: start;
	align-self: start;
	flex-wrap: nowrap;
	margin-top: 13px;
	width: 65%;

	${breakpoints.down("sm")} {
		flex-wrap: wrap;
		gap: 0;
	}
`;

const InputCustomized = styled(Input)`
	${breakpoints.down("sm")} {
		width: 345px;
	}
`;

const AutoCompleteCustomized = styled(CustomAutoComplete)`
	${breakpoints.down("sm")} {
		width: 345px;
	}
`;

const ButtonAddCulture = styled.button`
	border: 1px dotted ${colors.neutral.black1};
	cursor: pointer;
	display: block;
	border-radius: 8px;
	background-color: transparent;
	height: 40px;
	margin-top: 30px;
	margin-bottom: 15px;
	width: 200px;

	${breakpoints.down("sm")} {
		width: 100%;
	}
`;
const ButtonRemoveCulture = styled.button`
	padding: 5px 22px;
	margin-top: 10px;
	width: 150px;
	border: 1px dotted ${colors.neutral.black1};
	cursor: pointer;
	display: block;
	border-radius: 8px;
	background-color: transparent;
	height: 40px;
	margin-top: 75px;

	${breakpoints.down("sm")} {
		margin-top: 35px;
		margin-bottom: 25px;
		width: 100%;
	}
`;

const CheckboxCustomized = styled(Checkbox)`
	height: 15px;
	margin-top: 20px;
	line-height: 0;
	width: 15px;

	${breakpoints.down("sm")} {
		margin-right: 5px;
	}
`;

const FormTitle = styled.h2`
	color: ${colors.neutral.black1};
	display: inline-block;
	font-weight: 700;
	margin: 30px 0 40px 0;
`;
const SubTitle = styled.p`
	color: ${colors.neutral.gray03};
	display: block;
	font-size: 16px;
	line-height: 1.3;
	width: 100%;

	${breakpoints.down("sm")} {
		font-size: 17px;
	}
`;
const FormCustomized = styled.form`
	display: block;
	height: 100%;
	margin: 40px 0;
	max-height: 100%;
	max-width: 50%;
	width: 50%;
	padding: 0;

	${breakpoints.down("sm")} {
		padding: 0 2%;
		width: 96%;
		max-width: 100%;
	}
`;

const FormLabelCustomized = styled.label`
	color: ${colors.neutral.black1};
	font-size: 15px;
	font-weight: 600;
	line-height: 2.2rem;

	span {
		color: red;
	}
`;

const FormLabelCheckboxCustomized = styled(FormLabelCustomized)`
	line-height: 20px;
	margin-left: -20px;
	width: 100%;

	span {
		color: red;
	}

	${breakpoints.down("sm")} {
		width: 100%;
		max-width: 96%;
	}
`;

const FormRequiredFieldText = styled.p`
	color: ${colors.primary.red};
	font-size: 12px;
	margin: 35px 0 25px 0;

	${breakpoints.down("sm")} {
		font-size: 13px;
		margin: 28px 0 22px 0;
	}
`;

const Link = styled.a`
	color: ${colors.primary.orange01};
`;

const InputAcre = styled(Input)`
	width: 336px;
	border-radius: 8px;
	padding-left: 10px;
	border: 1px solid
		${(props) => (props.error ? colors.input.error : colors.generics.gray)}; // Define a cor da borda com base na propriedade error

	${breakpoints.down("sm")} {
		width: 100%;
		max-width: 100%;
	}

	&::before {
		position: absolute;
		display: ${(props) => (props.error ? "block" : "none")};
		content: "*Campo Obrigatório";
		color: ${colors.input.error};
		height: 20px;
		font-family: Helvetica, Arial, sans-serif;
		font-size: 13px;
		top: calc(100% + 2px);
		left: 5%;
		right: 0;
	}
`;

const BoxForms = styled.div`
	display: grid;
	margin-top: 40px;
	posi ${breakpoints.down("sm")} {
		width: 100%;
		max-width: 100%;
	}
	.asterisk {
		display: block;
		color: ${colors.input.error};
		font-size: 13px;
		margin-top: 2px;
		margin-left: 5%;
	}
`;

const InputPromotionCode = styled(Input)`
	margin: 0;
	width: 336px;
	padding-left: 10px;
	border-radius: 8px;
	border: 1px solid ${colors.generics.gray};

	${breakpoints.down("sm")} {
		width: 100%;
	}
`;
const ButtonCustomized = styled(Button)`
	margin-top: 20px;
`;
const ContainerSubTitleText = styled.section`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: center;
	max-width: 1000px;
	max-height: 100%;
	margin: 0;
	padding: 0 0 50px 20px;
	padding: 0 0 50px 20px;
	width: 100%;
	box-sizing: border-box;
	${breakpoints.up("lg")} {
		padding: 0 150px 50px;
	}

	${breakpoints.down(600)} {
		padding: 0 10px 20px;
	}
`;
export const ContainerFormStyled = {
	AutoCompleteCustomized,
	BoxForms,
	ButtonAddCulture,
	ButtonCustomized,
	ButtonRemoveCulture,
	CheckboxCustomized,
	ContainerCheckbox,
	ContainerCulture,
	ContainerCultureRow,
	ContainerForm,
	ContainerSubTitleText,
	FormCustomized,
	FormLabelCheckboxCustomized,
	FormLabelCustomized,
	FormRequiredFieldText,
	FormTitle,
	InputAcre,
	InputCustomized,
	InputPromotionCode,
	Link,
	NewContainerCultureRow,
	SubTitle,
};
