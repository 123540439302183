/* eslint-disable @typescript-eslint/member-ordering */
import { IGetParticipant } from "../../data/protocols/services/participant/get-participant";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";
import { StorageHelper } from "../../utils/functions/storage/storage";

export class HttpGetParticipant implements IGetParticipant {
  constructor(
    private readonly httpClient: HttpClient<
      IGetParticipant.Params,
      IGetParticipant.HttpResponse
    >,
    private readonly storageHelper: StorageHelper
  ) { }

  private headerSubscriptionKey = {
    Authorization: `Bearer ${this.storageHelper.get('token')}`,
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
    ["X-Session-Id"]: this.storageHelper.get("sessionKey"),
  };

  async getAll(params?: string): Promise<IGetParticipant.Result> {
    const result = await this.httpClient.request({
      headers: this.headerSubscriptionKey,
      method: HttpMethods.GET,
       url: `${process.env.REACT_APP_BASE_URL_API}participant/participants/participantDetails?document=${params}`,
    });
    return {
      response: result?.response.data,
      success: result?.success,
    } as IGetParticipant.Result;
  }
}
