import styled from "styled-components";
import { colors, breakpoints } from '../../../design-system/theme'
import { SearchFormProps } from '../../../design-system/domain/components/searchForm';
import Input from "../Inputs/Input/Input";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 100%;
    padding: 50px 150px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${colors.generics.grayLight};

    ${breakpoints.down(1280)} {
        padding: 50px 20px;
    }

    ${breakpoints.down('sm')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        margin: 0;
    }
`;

const Form = styled.form`
    height: 100%;
    margin-bottom: 50px;
    max-width: 100%;
    width: 100%;
`;
const InputSearchContainer = styled.div`
    position: relative;
    ${breakpoints.down('sm')} {
        width: 100%;
        padding-bottom: 30px;
    }
`

const InputSearchProvider = styled.input<SearchFormProps>`
    border: ${(props: SearchFormProps) => props?.border ? props?.border : `1px solid ${colors.neutral.gray05}`};
    border-radius: 0.25rem;
    color: ${(props: SearchFormProps) => props?.color ? props?.color : `${colors.neutral.gray02}`};
    height: 24px;
    max-width: 100%;
    min-width: 100px;
    margin: 0 25px 0 50px;
    padding: 0.6rem 1rem;
    width: 200px;

    &:focus {
        border-color: ${(props: SearchFormProps) => props?.backgroundColor ? props?.backgroundColor : `${colors.primary.orange01}`};
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(243 149 0 / 25%);
    }
    
    ${breakpoints.down('sm')} {
        font-size: 20px;
        margin: 0;
        text-align: center;
        width: 100%;
        padding: 0.6rem 0
    }
`;

const SelectSearchProvider = styled.select<SearchFormProps>`
    border: ${(props: SearchFormProps) => props?.border ? props?.border : `1px solid ${colors.neutral.gray05}`};
    border-radius: 0.25rem;
    color: ${(props: SearchFormProps) => props?.color ? props?.color : `${colors.neutral.gray02}`};
    height: 44px;
    max-width: 100%;
    min-width: 100px;
    padding: 0.6rem 1rem;
    width: 200px;

    &:focus {
        border-color: ${(props: SearchFormProps) => props?.backgroundColor ? props?.backgroundColor : `${colors.primary.orange01}`};
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(243 149 0 / 25%);
    }
    
    ${breakpoints.down('sm')} {
        font-size: 20px;
        margin: 0 0 35px;
        text-align: center;
        width: 100%;
    }
`;

const OptionSelectSearchProvider = styled.option<SearchFormProps>`
    color: ${(props: SearchFormProps) => props?.color ? props?.color : `${colors.neutral.gray02}`};
    font-size: 17px;
`;

const SearchProviderButton = styled.button<SearchFormProps>`
    background-color: ${(props: SearchFormProps) => props?.backgroundColor ? props?.backgroundColor : `${colors.primary.orange01}`};
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: ${(props: SearchFormProps) => props?.color ? props?.color : `${colors.neutral.backgroundTwo}`};
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 1rem;
    height: 40px;
    line-height: 1.5;
    margin-left: 30px;
    padding: 0.6rem 1rem;
    text-align: center;
    transition: 0.3s ease-in;
    vertical-align: middle;
    
    &:hover {
        background-color: ${(props: SearchFormProps) => props?.backgroundColor ? props?.backgroundColor : colors.primary.orange03};
    }

    ${breakpoints.down('sm')} {
        font-size: 20px;
        margin: 15px 0;
        max-width: 100%;
        text-align: center;
        width: 100%;
    }
`;

const CleanSearchFormButton = styled.button<SearchFormProps>`
    background-color: ${(props: SearchFormProps) => props?.backgroundColor ? props?.backgroundColor : colors.primary.orange01};
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: ${(props: SearchFormProps) => props?.color ? props?.color : colors.neutral.backgroundTwo};
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.700rem 0.85rem;
    text-align: center;
    transition: 0.3s ease-in;
    vertical-align: middle;

    &:hover {
        background-color: ${(props: SearchFormProps) => props?.backgroundColor ? props?.backgroundColor : colors.primary.orange03};
    }

    ${breakpoints.down('sm')} {
        display: block;
        font-size: 20px;
        margin: 25px 0 15px;
        text-align: center;
        width: 100%;
    }
`;

const FormSpanError = styled.span<SearchFormProps>`
    color: ${(props: SearchFormProps) => props?.color ? props?.color : `${colors.status.error}`};
    position: absolute;
    left:0;
    font-size: 13px;
    bottom: 0;
    ${breakpoints.up(980)} {
        bottom: -20px;
        margin: 0 25px 0 20px;
    }
`;

const InputCustomized = styled(Input)`
    margin-left: 15px;
`

export const SearchFormStyled = {
    CleanSearchFormButton,
    Container,
    Form,
    FormSpanError,
    InputCustomized,
    InputSearchContainer,
    InputSearchProvider,
    OptionSelectSearchProvider,
    SearchProviderButton,
    SelectSearchProvider,
}