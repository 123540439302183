import React from "react";
import { Modal } from "antd";
import * as ModalLogoutStyled from './styled'

export type ModalLogoutProps = {
	isOpen: boolean
}

const ModalLogout: React.FC<ModalLogoutProps> = ({
	isOpen
}) => {
	return (
		<Modal
			width={"100%"}
			style={{ maxWidth: "360px" }}
			zIndex={10000000000}
			title="SUA SESSÃO FOI ENCERRADA!"
			open={isOpen}
			footer={
				<ModalLogoutStyled.ModalFooter>
					<button
						className="see-now"
						onClick={() => window.location.replace("/logout")}
					>
						Ir para o Conecta.ag
					</button>
				</ModalLogoutStyled.ModalFooter>
			}
		>
			<ModalLogoutStyled.ModalContent>
				<p className="modal-content-text">
					Mas não se preocupe, clique no botão abaixo para iniciar uma nova sessão.
				</p>
			</ModalLogoutStyled.ModalContent>
		</Modal>
	)
}

export default ModalLogout