/* eslint-disable sort-keys */
import { useSelector } from "react-redux";
import { Http } from "api";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useTitle } from "hooks";
import { Styled } from "./style";
import { TitlePage, TableList, ContainerPage } from "design-system/components";
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpRelashionship } from "services/relashionship/relashionship"
import { IRelashionship } from "data/protocols/services/relashionship/relashionship"
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import ModalLogout from "modal/modal-logout";
 
const RelashionshipPage = () => {
  const [msgError, setMsgError] = useState('');
  const [showModalLogout, setShowModalLogout] = useState<boolean>(false)
  const [relashionshipData, setRelashionshipData] =
    useState<IRelashionship.RowsAndTotal>({
      message: "",
      rows: [],
      total: 0,
    });
  const { user } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user.is_logged) {
      navigate("/programa");
    } else {
      fetchRelashionship();
    }
 
  }, []);
  useTitle('Contas Agregadas');
 
  const HttpRelashionshipa = new HttpRelashionship(
    new Http(),
    new StorageHelper()
  );
 
  const fetchRelashionship = async () => {
    setMsgError('');
 
    try {
      const result = await HttpRelashionshipa.getRelashionship({
        data: {
          documentNumber: user.document_number as string,
        },
      });
 
      setMsgError(!result.total ? 'Você não tem contas agregadas ao seu CPF' : '');
 
      if (result.total)
        setRelashionshipData({
          message: result.message,
          rows: result.rows,
          total: result.total
        });
 
    } catch (error: any) {
      setShowModalLogout(error.response.status === 401);
      setMsgError(error.response.data.message);
    }
  };
 
  useEffect(() => {
    if (!user.is_logged) {
      navigate("/");
    }
  }, [user]);
 
 
 
  return (
    <>
      <ModalLogout isOpen={showModalLogout} />
      <ContainerPage>
        <TitlePage
          title={`Consulte aqui as contas agregadas ao seu CPF`}
        />
 
        <Styled.ContainerSubTitleText>
          <Styled.SubTitleText>
            <p>
              O Programa AGREGA permite a agregação de CPFs ou CNPJS com finalidade de consolidar os pontos em uma única conta titular.
            </p>
            <br />
            <p>
              Clique <Styled.link href="/regulamento">aqui</Styled.link> e veja mais detalhes da regra no regulamento.
              <br />
              Caso queira agregar sua conta a um outro participante, entre em contato com nossa Central de Atendimento.
            </p>
 
          </Styled.SubTitleText>
          <Styled.SubTitleText>
 
          </Styled.SubTitleText>
        </Styled.ContainerSubTitleText>
        <Styled.SubTitleTextError>
          {msgError != '' ? (<p>{msgError}</p>) : null}
        </Styled.SubTitleTextError>
        <TableList
          data={{
            columnTitles: [
              "Tipo de relacionamento",
              "Documento",
              "Data de vínculo",
              "Status",
            ],
            rows: relashionshipData.rows != null ? relashionshipData.rows.map(
              (relashionship: IRelashionship.RelashionshipRow) => ({
                ["Tipo de relacionamento"]: relashionship.typeRelashionship,
                ["Documento"]: relashionship.documentNumber,
                ["Data de vínculo"]: moment(relashionship.dataAssociated).format("DD-MM-YYYY"),
                ["Status"]: relashionship.status.toString(),
              })
            ) : [],
            totalItems: relashionshipData.total,
          }}
        />
      </ContainerPage>
    </>
  );
};
 
export default RelashionshipPage;