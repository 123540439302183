/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { SearchFormStyled } from "./styled";
import SelectSearch from "../Inputs/SelectSerch/SelectSearch";

const SearchForm: FC<{
  fetchProviders: (operation?: 'NEXT' | 'PREV' | 'START' | 'LAST') => Promise<void>;
  setCustomParam: any;
}> = ({ fetchProviders, setCustomParam }) => {
  const [selectValue, setSelectValue] = useState<string>("");
  const [inputSearch, setInputSearch] = useState<string>("");
  const [clearForm, setClearForm] = useState<boolean>(false);

  const filterProvidersByParam = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    fetchProviders('START');
  };

  const handleClearForm = (e: SyntheticEvent): string | void => {
    e.preventDefault();
    setClearForm(false);
    setInputSearch("");
  };

  useEffect(() => {
    setCustomParam({
      [selectValue]: inputSearch,
    });
  }, [selectValue, inputSearch]);

  return (
    <SearchFormStyled.Container>
      <Box sx={{ minWidth: 120 }}>
        <FormControl
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
          fullWidth
        >

          <SelectSearch
            onChange={(e: unknown) => {
              setSelectValue(e as string)
            }}
            placeholder="Busque por:"
            options={
              [
                {
                  key: 1,
                  label: 'Distribuidor',
                  value: 'Name'
                },
                {
                  key: 1,
                  label: 'Cidade',
                  value: 'City'
                },
                {
                  key: 1,
                  label: 'UF',
                  value: 'State'
                },
              ]
            }
          />
          <SearchFormStyled.InputSearchContainer>
            <SearchFormStyled.InputCustomized
              placeholder="Buscar por distribuidores"
              value={inputSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputSearch(e.target.value);
              }}
              disabled={clearForm}
              maxCharLenght={inputSearch === "state" ? 2 : 99}
            />
            {selectValue && !inputSearch && (
              <SearchFormStyled.FormSpanError>
                * Preenchimento obrigatório.
              </SearchFormStyled.FormSpanError>
            )}
          </SearchFormStyled.InputSearchContainer>
          {!clearForm ? (
            <SearchFormStyled.SearchProviderButton
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                filterProvidersByParam(e)
              }
            >
              Pesquisar
            </SearchFormStyled.SearchProviderButton>
          ) : (
            <SearchFormStyled.CleanSearchFormButton onClick={handleClearForm}>
              Limpar
            </SearchFormStyled.CleanSearchFormButton>
          )}
        </FormControl>
      </Box>
    </SearchFormStyled.Container >
  );
};
export default SearchForm;
