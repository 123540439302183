/* eslint-disable @typescript-eslint/member-ordering */
import { IGetUserScore } from "../../data/protocols/services/account/score";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";

export class HttpGetUserScore implements IGetUserScore {
	constructor(
		private readonly httpClient: HttpClient<
			IGetUserScore.Params,
			IGetUserScore.Result
		>
	) {}

	private headerSubscriptionKey = {
		"Access-Control-Allow-Origin": "*",
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_SUBSCRIPTION_KEY
		),
	};

	async getScoreByUser(
		params?: IGetUserScore.Params
	): Promise<{ pointsValue: number; statusCode: number }> {
		const result = await this.httpClient.request({
			data: params?.data,
			headers: {
				...this.headerSubscriptionKey,
				Authorization: `Bearer ${params?.data?.token}`,
				["X-Session-Id"]: String(params?.data?.xSessionId),
			},
			method: HttpMethods.GET,
			url: `${process.env.REACT_APP_BASE_URL_API}participant/participants/simpleBalance/${params?.data?.documentNumber}`,
		});

		return {
			pointsValue: Number(result?.response.data.pointsValue),
			statusCode: Number(result?.statusCode),
		};
	}
}
