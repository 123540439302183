import { Select } from "antd";
import styled from "styled-components";
import InputMask from "react-text-mask";
import { breakpoints, colors } from "design-system/theme";

const ConatinerView = styled["div"]`
	padding: 45px 50px;
	display: flex;
	flex-direction: column;
	align-items: center;

	${breakpoints.up("lg")} {
		display: grid;
		justify-content: center;
	}
`;

const SelectStyled = styled(Select)`
	width: 45vh;
	margin-top: 35px;
`;

const SelectWrap = styled["div"]`
	display: flex;
	flex-direction: column;
	width: 45vh;
`;

const BarcodeMaskedInput = styled(InputMask)`
	border: 1px solid ${colors.generics.gray};
	color: rgb(112 102 102);
	font-size: 17px;
	margin-top: 35px;
	height: 35px;
	padding: 0 8px;
	width: 43vh;
	border-radius: 5px;
	::placeholder {
		color: #e0e0e0;
		font-size: 0.875rem;
	}
`;

const BarcodeInstruction = styled.span`
	color: ${colors.generics.darkBlue};
	font-size: 10px;
	margin-top: 5px;
`;

const BoxBarcodeAndButton = styled.div`
	display: block;
`;

const ButtonSaveBarCode = styled.button`
	background: ${colors.status.success};
	border: none;
	border-radius: 6px;
	color: ${colors.generics.white};
	cursor: pointer;
	height: 47px;
	margin-top: 25px;
	width: 46vh;

	&:disabled {
		background: gray;
	}
`;

const BoxUseCamera = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40px;
	width: 100%;

	${breakpoints.up("lg")} {
		display: none;
	}
`;

const ButtonUseCamera = styled.button`
	background: transparent;
	border: 1px solid gray;
	border-radius: 15px;
	color: gray;
	height: 30px;
	width: 50%;
`;

const ContainerCamera = styled.div`
	video {
		margin-left: 55px;
		margin-top: 40px;
		width: 204px !important;
	}

	canvas {
		margin-left: 40px;
		margin-top: -160px;
		position: initial;
		width: 220px;
	}
`;
const SubTitleText = styled.p`
	color: ${colors.neutral.gray03};
	display: block;
	font-size: 17px;
	line-height: 1.5;
	padding: 0 20px;
	margin-left: 30px;
	margin-top: 20px;
	width: 75%;

	${breakpoints.up("md")} {
 		margin-left: 150px;
		font-size: 16px;
		width: 900px;
	}
`;

const MessageNotificationSuccess = styled.h3`
	color: ${colors.status.success};
`;
const MessageNotificationError = styled.h3`
	color: ${colors.status.error2};
`;

const MessageSearcYourNF = styled.div`
	color: ${colors.neutral.gray03};
	font-size: 12px;
	font-style: regular;
	margin-top: 25px;
	text-align: justify;
	width: 45vh;

	a {
		text-decoration: underline;
	}
`;

export const RegisterNFPageStyled = {
	BarcodeInstruction,
	BarcodeMaskedInput,
	BoxBarcodeAndButton,
	BoxUseCamera,
	ButtonSaveBarCode,
	ButtonUseCamera,
	ConatinerView,
	ContainerCamera,
	MessageNotificationError,
	MessageNotificationSuccess,
	MessageSearcYourNF,
	SelectStyled,
	SelectWrap,
	SubTitleText
};
