import styled from "styled-components";
import { CircularLoading } from "../../design-system/components";
import { ErrorIcon } from "../../design-system/icons/geral-icons";

const Container = styled.div`
  height: 300px;
  text-align: center;
  padding-top: 50px;
`;

const LabelCheckTokenStatus = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

const BoxLoading = styled.div`
  display: grid;
  justify-content: center;
`;

const CircularLoadingCustomized = styled(CircularLoading)`
  margin: 30px 0 0 50px;
`;

const ErrorIconCustomized = styled(ErrorIcon)`
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 45px !important;
  }
  margin-top: 15px;
`;

export const AuthPageStyled = {
  BoxLoading,
  CircularLoadingCustomized,
  Container,
  ErrorIconCustomized,
  LabelCheckTokenStatus,
};
