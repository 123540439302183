import { FC } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { appReducer } from "store";
import { colors, GlobalStyle } from "design-system/theme";
import { Footer, Header, Layout } from "./design-system/components";

//Import Pages
import { ProviderPage } from "./pages/ProviderPage";
import { ProgramPage } from "./pages/ProgramPage/";
import {
	TermAndConditionPage,
	InterceptionAcceptanceRegulation,
} from "./pages/TermAndCondition";
import { LegacyExtractPage } from "./pages/LegacyExtractPage";
import { RelashionshipPage } from "./pages/RelashionshipPage";
import { ProductsList } from "./pages/ProductPage";
import { RegisterPage } from "./pages/RegisterPage";
import { AuthPage } from "./pages/Auth";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import RegisterNFPage from "./pages/RegisterNFPage/RegisterNFPage";
import { HomePage } from "./pages/HomePage";
import { LogoutPage } from "./pages/Logout";
import { BlockedPage } from "./pages/BlockedPage";
import { Maintenance } from "./pages/Maintenance";
import { ToastContainer } from "react-toastify";

const App: FC = () => {
	ReactGA.initialize("G-128CF005WC");

	const persistor = persistStore(appReducer);
	if (process.env.REACT_APP_MAINTENANCE === "isMaintenance") {
		return <Maintenance />;
	}
	return (
		<Provider store={appReducer}>
			<PersistGate persistor={persistor}>
				<GlobalStyle />
				<Layout>
					<Header
						hideFirstRowHeader={false}
						backgroundColor={colors.generics.white}
					/>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<HomePage />} />
							<Route path="/distribuidores" element={<ProviderPage />} />
							<Route path="/programa" element={<ProgramPage />} />
							<Route path="/regulamento" element={<TermAndConditionPage />} />
							<Route
								path="/resgatar"
								element={<InterceptionAcceptanceRegulation />}
							/>
							<Route path="/produtos" element={<ProductsList />} />
							<Route path="/extrato" element={<LegacyExtractPage />} />
							<Route path="/relacionamentos" element={<RelashionshipPage />} />
							<Route path="/cadastro" element={<RegisterPage />} />
							<Route path="auth" element={<AuthPage />} />
							<Route
								path="/cadastrar-minhas-notas-fiscais"
								element={<RegisterNFPage />}
							/>
							<Route path="/logout" element={<LogoutPage />} />
							<Route path="/acesso-restrito" element={<BlockedPage />} />
						</Routes>
					</BrowserRouter>
					<Footer />
				</Layout>
			</PersistGate>
			<ToastContainer />
		</Provider>
	);
};

export default App;
