import { FC } from "react";
import { SelectSearchStyled } from "./styled";
import { SelectSearchProps } from "../../../../design-system/domain/components";

const SelectSearch: FC<SelectSearchProps> = ({
  dropdownMatchSelectWidth,
  options,
  onChange,
  placeholder,
  width,
  value,
  status
}) => {
  return (
    <>
      {onChange ? (
        <SelectSearchStyled.CustomSearch
          onChange={(e: unknown) => onChange(e)}
          dropdownMatchSelectWidth={
            dropdownMatchSelectWidth ? dropdownMatchSelectWidth : 400
          }
          placeholder={placeholder}
          showSearch={true}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="large"
          style={{ width: width ? width : 220 }}
          options={options}
          value={value}
          status={status}
        />
      ) : (
        <SelectSearchStyled.CustomSearch
          dropdownMatchSelectWidth={
            dropdownMatchSelectWidth ? dropdownMatchSelectWidth : 400
          }
          showSearch={true}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder={placeholder}
          size="large"
          style={{ width: width ? width : 220 }}
          options={options}
        />
      )}
    </>
  );
};

export default SelectSearch;
