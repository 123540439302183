import { FC } from "react"
import { MosaicProps } from "../../../design-system/domain/components"
import { MosaicStyled } from "./styled"
import { MosaicImages } from "../../../design-system/images"
import { colors } from "../../../design-system/theme"

const Mosaic: FC<MosaicProps> = ({
    alt,
    id
}) => {

    return (
        <>
            <MosaicStyled.MosaicContainer id={id}>
                {/*Row 1*/}
                <MosaicStyled.MosaicRow>
                    <MosaicStyled.MosaicBlockContainer style={{ marginTop: "-50px" }}>
                        <MosaicStyled.MosaicImg alt={alt} src={MosaicImages.MosaicSmallImage3Row1} width="100px" height="55px" style={{ alignSelf: "end" }} />
                        <MosaicStyled.MosaicBlock width="270px" height="287px" />
                    </MosaicStyled.MosaicBlockContainer>
                    <MosaicStyled.MosaicImg alt={alt} src={MosaicImages.MosaicImage1Row1} border={`15px solid ${colors.primary.orange02}`} width="260px" height="260px"/>
                    <MosaicStyled.MosaicBlockContainer style={{marginTop: "25px"}}>
                        <MosaicStyled.MosaicBlock width="40px" height="40px" />
                        <MosaicStyled.MosaicBlock width="220px" height="205px" />
                    </MosaicStyled.MosaicBlockContainer>
                    <MosaicStyled.MosaicImg alt={alt} src={MosaicImages.MosaicImage2Row1} width="270px" height="270px" />
                </MosaicStyled.MosaicRow>

                {/*Row 2*/}
                <MosaicStyled.MosaicRow >
                    <MosaicStyled.MosaicImg alt={alt} src={MosaicImages.MosaicImage1Row2} width="270px" height="310px" />
                    <MosaicStyled.MosaicBlockContainer style={{ marginTop: "-18px" }}>
                        <MosaicStyled.MosaicBlock width="240px" height="240px"/>
                        <MosaicStyled.MosaicImg alt={alt} src={MosaicImages.MosaicSmallImageRow2} width="40px" height="40px" />
                    </MosaicStyled.MosaicBlockContainer>
                    <MosaicStyled.MosaicImg alt={alt} src={MosaicImages.MosaicImage2Row2} width="220px" height="280px" style={{marginTop: "-36px"}}/>
                    <MosaicStyled.MosaicBlockContainer style={{flexDirection: "row", marginTop: "-45px"}}>
                        <MosaicStyled.MosaicBlock width="250px" height="250px" style={{marginTop: "-5px"}}/>
                        <MosaicStyled.MosaicImg alt={alt} src={MosaicImages.MosaicSmallImageRow1} width="50px" height="50px" />
                    </MosaicStyled.MosaicBlockContainer>
                </MosaicStyled.MosaicRow>
            </MosaicStyled.MosaicContainer>
        </>
    )

}
export default Mosaic 