import styled from "styled-components";

export const ContainerFull = styled.div`
	text-align: center;
	background: #fff;
	width: 100%;
	height: 95vh;
	top: 0;
	z-index: 55555554645;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Arial;
	overflow-x: hidden;

	h1 {
		color: #f39500;
		font-size: 42px;
		font-weight: bold;
		margin-bottom: 15px;
	}

	h2 {
		font-size: 30px;
		color: #808080;
		line-height: 40px;
		font-weight: 500;
		padding: 0;
		margin: 0;
	}

	strong {
		color: #f39500;
	}

	img {
		position: relative;
		top: -110px;
		@media all and (max-width: 768px) {
			width: 100%;
			top: 0;
		}
	}
`;
