import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { TermAndConditionStyled } from "./styled";
import { BoxScroll, Modal } from "design-system/components";
import RegulamentPDF from "./regulamento.pdf";

export const ModalContent = () => {
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [numPages, setNumPages] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);

	const RegulationLink = RegulamentPDF;
	//`https://cryptic-headland-94862.herokuapp.com/https://apiagregabasf.caosdata.com.br/images/REGULAMENTO%20PROGRAMA%20AGREGA_AGRICULTOR_VF_Mar2022%20(1).pdf`

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
		setPageNumber(1);
	};

	const changePage = (offset: number) => {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	};

	const previousPage = () => {
		changePage(-1);
	};

	const nextPage = () => {
		changePage(1);
	};

	const hendleAccept = () => {
		window.localStorage.setItem("TermAndConditionAccept", "ok");
		window.location.replace("/programa");
	};

	return (
		<>
			<BoxScroll height={400}>
				<Document file={RegulationLink} onLoadSuccess={onDocumentLoadSuccess}>
					<Page pageNumber={pageNumber} />
				</Document>
				<TermAndConditionStyled.Paginator>
					Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}{" "}
				</TermAndConditionStyled.Paginator>
				<TermAndConditionStyled.ContainerButtons>
					{pageNumber > 1 && (
						<a onClick={previousPage}>
							<TermAndConditionStyled.TermDownloadButton buttonText="< Página anterior" />
						</a>
					)}
					{pageNumber < numPages && (
						<a onClick={nextPage}>
							<TermAndConditionStyled.TermDownloadButton buttonText="Próxima página >" />
						</a>
					)}
				</TermAndConditionStyled.ContainerButtons>
			</BoxScroll>
			<TermAndConditionStyled.TermAndConditionContainerButton>
				<a
					rel="noreferrer"
					target="_blank"
					href={RegulationLink}
					download={true}
				>
					<TermAndConditionStyled.TermDownloadButton buttonText="Download do Regulamento" />
				</a>
				<a onClick={hendleAccept}>
					<TermAndConditionStyled.TermAcceptButton buttonText="Aceito os termos" />
				</a>
			</TermAndConditionStyled.TermAndConditionContainerButton>
		</>
	);
};

const InterceptionAcceptanceRegulation = () => {
	const [statusModalLogin, setStatusModalLogin] = useState<boolean>(true);

	const handleAccept = () => {
		window.location.href = "/cadastro";
		setStatusModalLogin(false);
	};

	const handleCloseModalLogin = () => {
		setStatusModalLogin(false);
		window.location.replace("/programa");
	};

	return (
		<>
			<TermAndConditionStyled.Container>
				<Modal
					title={`Para participar do programa AGREGA você deve complementar o seu cadastro.`}
					content={
						<>
							<TermAndConditionStyled.CloseButtom
								onClick={handleCloseModalLogin}
							>
								X
							</TermAndConditionStyled.CloseButtom>
							<TermAndConditionStyled.ModalFooter>
								<div style={{ margin: "0 auto" }}>
									<a onClick={handleAccept}>
										<TermAndConditionStyled.CustomConfirmButton buttonText="Desejo continuar." />
									</a>
								</div>
							</TermAndConditionStyled.ModalFooter>
						</>
					}
					titleAlign={`center`}
					open={statusModalLogin}
					size={`SMALL`}
					zindex={9999}
					boxShadow={`0 0 0 0`}
				/>
				<Modal
					title={`Para acessar o catálogo é necessário que você esteja em sua conta do Programa conecta.ag`}
					content={
						<>
							<TermAndConditionStyled.CloseButtom
								onClick={handleCloseModalLogin}
							>
								X
							</TermAndConditionStyled.CloseButtom>
							<TermAndConditionStyled.ModalFooter>
								<div style={{ margin: "0 auto" }}>
									<a onClick={handleAccept}>
										<TermAndConditionStyled.CustomConfirmButton buttonText="Desejo continuar." />
									</a>
								</div>
							</TermAndConditionStyled.ModalFooter>
						</>
					}
					titleAlign={`center`}
					open={statusModalLogin}
					size={`SMALL`}
					zindex={9999}
					boxShadow={`0 0 0 0`}
				/>
			</TermAndConditionStyled.Container>
		</>
	);
};

export default InterceptionAcceptanceRegulation;
