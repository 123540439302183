/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from "react";
import { ContainerPage, TableList } from "../../../design-system/components";

interface Product {
  category: string;
  point: string;
  productName: string;
} 

class ProductList {
  private apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async getProductList(): Promise<Product[]> {
    try {
      const headers = {
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Origin': '*',
        'Ocp-Apim-Subscription-Key': String(
          process.env.REACT_APP_SUBSCRIPTION_KEY
        )
      };
      const options = {
        headers: headers,
        method: 'GET',
      }
      const response = await fetch(`${this.apiUrl}hotsite/product/getall`, options);

      if (response.ok) {
        const data = await response.json();
        return data as Product[];
      } else {
        throw new Error('Erro ao obter a lista de produtos');
      }
    } catch (error: any) {
      throw new Error(`Erro: ${(error as Error).message}`);
    }
  }
}

const StackCustomized: FC = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    // const headers = {
    //   'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    //   'Access-Control-Allow-Origin': '*',
    //   'Ocp-Apim-Subscription-Key': String(
    //     process.env.REACT_APP_SUBSCRIPTION_KEY
    //   )
    // };
    // const options = {
    //   headers: headers,
    //   method: 'GET',
    // }

    const productList = new ProductList(process.env.REACT_APP_BASE_URL_API || '');
    productList.getProductList()
      .then((productData) => {
        setProducts(productData);
      })
      .catch((error) => {
        console.error('Erro ao carregar produtos:', error);
      });
  }, []);

  return (
    <ContainerPage>
      <TableList
        data={{
          columnTitles: ["Categoria", "Produtos", "Pontos"],
          rows: products.map((p) => ({
            ["Categoria"]: p.category,
            ["Pontos"]: p.point !== undefined ? p.point.toString() : "",
            ["Produtos"]: p.productName,

          })),
          totalItems: products.length, // Defina o total de itens como o tamanho da lista de produtos
        }}
      />
    </ContainerPage>
  );
};

export default StackCustomized;