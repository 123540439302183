import { FC } from "react";
import styled from "styled-components";
import { colors, breakpoints } from "../../../design-system/theme";
import { TableListProps } from "../../../design-system/domain/components";

const Container: FC = styled.div`
  display: block;
  max-width: 100%;
  height: auto;
  padding: 0 2%;
  margin: 0 auto;
`;

const TableLess = styled.div`
  padding: 0 150px 100px;
  box-sizing: border-box;
  background-color: ${colors.generics.grayLight};

  ${breakpoints.down(1280)} {
    padding: 50px 20px;
  }
`;

const StyledTableHead = styled.th<TableListProps>`
  border: ${(props: TableListProps) =>
    props?.border ? props?.border : "none"};
  color: ${(props: TableListProps) =>
    props?.color ? props?.color : `${colors.generics.darkBlue}`};
  background-color: ${(props: TableListProps) =>
    props?.backgroundColor ? props?.backgroundColor : "inherit"};
  padding: 0.75rem;
  text-align: left;
`;

const StyledTableCellNotFound = styled.td<TableListProps>`
  border: ${(props: TableListProps) =>
    props?.border ? props?.border : "none"};
  color: ${(props: TableListProps) =>
    props?.color ? props?.color : `${colors.generics.darkBlue}`};
  background-color: ${(props: TableListProps) =>
    props?.backgroundColor ? props?.backgroundColor : "inherit"};
  padding: 40px;
  text-align: center;
`;

const StyledTableCell = styled.td<TableListProps>`
  border: ${(props: TableListProps) =>
    props?.border ? props?.border : "none"};
  color: ${(props: TableListProps) =>
    props?.color ? props?.color : `${colors.generics.darkBlue}`};
  background-color: ${(props: TableListProps) =>
    props?.backgroundColor ? props?.backgroundColor : "inherit"};
  padding: 0.75rem;
`;

const StyledTableRow = styled.tr<TableListProps>`
  box-shadow: none;
  border-collapse: collapse;
  border: ${(props: TableListProps) =>
    props?.border ? props?.border : "none"};

  &:nth-child(odd) {
    background-color: ${(props: TableListProps) =>
      props?.backgroundColor
        ? props?.backgroundColor
        : `${colors.generics.grayLight}`};
  }
`;

const LoadingContainer = styled.div`
  height: 60vh;
  padding: 30px 0;
  text-align: center;
`;

export const TableStyled = {
  Container,
  LoadingContainer,
  StyledTableCell,
  StyledTableCellNotFound,
  StyledTableHead,
  StyledTableRow,
  TableLess,
};
