/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/member-ordering */
import { StorageHelper } from "../../utils/functions/storage/storage";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";
import { ILoadLegacyExtract } from "../../data/protocols/services/extract/legacy-extract";

export class HttpLegacyExtract implements ILoadLegacyExtract {
	constructor(
		private readonly httpClient: HttpClient<
			ILoadLegacyExtract.Params,
			ILoadLegacyExtract.Result
		>,
		private readonly storageHelper: StorageHelper
	) {}

	private headerSubscriptionKey = {
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${this.storageHelper.get("token")}`,
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: this.storageHelper.get("sessionKey"),
		
		cutOffDate: "",
	};

	async getExtract(
		params?: ILoadLegacyExtract.Params
	): Promise<ILoadLegacyExtract.RowsAndTotal> {
		const result = await this.httpClient.request({
			headers: this.headerSubscriptionKey,
			method: HttpMethods.GET,
			url: `${process.env.REACT_APP_BASE_URL_API}participant/invoice/search?&startDate=${params?.data?.startDate}&endDate=${params?.data?.endDate}&documentParticipant=${params?.data?.documentNumber}`,
		});
		return {
			message: result?.response?.message,
			rows: result?.response?.data.rows,
			statusCode: result?.statusCode,
			total: result?.response?.data?.total,
		} as ILoadLegacyExtract.RowsAndTotal;
	}
}
