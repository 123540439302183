import styled from 'styled-components'
import { breakpoints, colors } from 'design-system/theme'
import { Button } from 'design-system/components'

const ButtomAccessConecta = styled(Button)`
    background-color: ${colors.primary.red};
    border-radius: 35px;
    color: ${colors.generics.white};
    cursor: pointer;
    outline: 0;
    width: 55%;

    &:hover {
      background-color: ${colors.primary.red};
    }

    ${breakpoints.up('lg')} {
        margin-left: 10vh;
        width: 35%;
    }
`

const RowTextContent = styled.div`
    padding: 0 20px;
    width: 80%;

    p {
        color: rgb(150, 150, 150);
        font-size: 20px; 
        line-height: 30px;
    }

    ${breakpoints.up('lg')} {
        padding: 12px 150px 0;
        width: 45%;
    }
`

const RowButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;


    ${breakpoints.up('lg')} {
        padding: 50px 150px 0px;
        width: 45%;
    }
`

export const BlockedPageStyled = {
    ButtomAccessConecta,
    RowButton,
    RowTextContent
}