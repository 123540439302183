import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "../../../design-system/theme";
import { CircularLoadingProps } from "../../../design-system/domain/components";

const CircularLoading: React.FC<CircularLoadingProps> = ({ className, color }) => (
  <Stack
    sx={{ color: "grey.500" }}
    spacing={2}
    direction="row"
    className={className}
  >
    <CircularProgress
      sx={{
        color: color ? color : colors.primary.orange02,
      }}
    />
  </Stack>
);

export default CircularLoading;
