import { colors } from "../../../design-system/theme";
import styled from "styled-components";

const AccordionContainer = styled.section`
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    width: 100%;
`;

const AccordionIconCustomized = styled.img`
    cursor: pointer;
    fill: ${colors.neutral.gray04} !important;
    font-size: 45px !important;
`;

export const AccordionStyled = {
    AccordionContainer,
    AccordionIconCustomized
}