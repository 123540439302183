/* eslint-disable @typescript-eslint/no-explicit-any */
export interface HttpResponse<R = any> {
  data: R;
  statusCode: number;
}

export type Method = "get" | "post" | "patch" | "GET" | "POST" | "PATCH";

export enum HttpMethods {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}
export type HttpRequest<D = any> = {
  data?: D;
  headers?: any;
  method?: Method;
  url?: string;
};
export interface HttpClient<D = any, R = any> {
  request: (params?: D) => Promise<{ response: R; statusCode: number; success: boolean } | void>
}

export namespace HttpClient {
  export type Response<R = object> = HttpResponse<R>;
  export type Params<D = object> = HttpRequest<D>;
}