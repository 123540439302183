import { FC } from "react";
import {
  InputProps,
  SelectProps,
  AutoCompleteProps,
  ListProps,
} from "../../../../design-system/domain/components";
import Input from "../Input/Input";

const CustomAutoComplete: FC<InputProps & SelectProps & AutoCompleteProps> = ({
  className,
  customStyle,
  disabled,
  id,
  list,
  optionsList,
  onChange,
  placeholder,
  required,
  type,
  value,
}) => (
  <>
    <Input
      id="itemId"
      className={className}
      type={type}
      list={list}
      style={customStyle}
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    <datalist id={id}>
      {optionsList.map((option: ListProps, key: number) => (
        <option key={key} value={option.optionValue} />
      ))}
    </datalist>
  </>
);

export default CustomAutoComplete;
