/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, Fragment, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { HeaderStyled } from "./styled";
import { HeaderImages } from "../../../design-system/images";
import { HeaderProps } from "../../../design-system/domain";
import { colors } from "../../../design-system/theme";
import { RootState } from "../../../store";
import { Http } from "../../../api";
import { HttpRegistrationRedirect } from "../../../services/account-user/redirect";
import { StorageHelper } from "../../../utils/functions/storage/storage";
import {
	ModalUserIsNotLogged,
	ModalRedirectComplementAccount,
} from "../../../pages/Modal";
import { UserState } from "../../../store/domain";
import OptinComunication from "../../../pages/Modal/OptinComunication/OptinComunication";
import ModalLogout from "../../../modal/modal-logout";
import { useRedirectCatalog } from "../../hooks/useRedirectCatalog";

const Header: FC<HeaderProps> = ({ backgroundColor, hideFirstRowHeader }) => {
	const [showSubItemsMenu, setShowSubItemsMenu] = useState<boolean>(false);
	const [showUserMenu, setshowUserMenu] = useState<boolean>(false);
	const { user } = useSelector((state: RootState) => state);

	const http = new Http();
	const storageHelper = new StorageHelper();

	const httpRegistrationRedirect = new HttpRegistrationRedirect(
		http,
		storageHelper
	);

	const { 
		handleRedirectCatalog, 
		modalOptionComunication, 
		modalRedirectComplementAccountIsOpen, 
		modalUserIsNotLogged, 
		showModalLogout, 
		queryParamValue, 
		setModalOptionComunication, 
		setModalRedirectComplementAccountIsOpen,
		setModalUserIsNotLogged,
		setQueryParamValue,
		setShowModalLogout
	} = useRedirectCatalog();

	const menuItems = [
		{
			customEvent: (_user: UserState) => (window.location.href = "/programa"),
			link: "/programa",
			title: "Programa",
		},
		{
			customEvent: (_user: UserState) =>
				(window.location.href = "/regulamento"),
			link: "/regulamento",
			title: "Regulamento",
		},
		{
			customEvent: (_user: UserState) =>
				(window.location.href = "/distribuidores"),
			link: "/distribuidores",
			title: "Distribuidores",
		},
		{
			customEvent: (_user: UserState) => (window.location.href = "/produtos"),
			link: "/produtos",
			title: "Produtos",
		},
		{
			alternativeLink: "/cadastro",
			customEvent: async (user: UserState) => {
				const isUserOptInValid =
					user.agrega_opt_in_conecta_ag &&
					user.agrega_opt_in_score &&
					user.agrega_opt_in_opt_in_terms_and_privacy;
				if (!user.is_logged) {
					setQueryParamValue("catalog");
					setModalUserIsNotLogged(true);
				} else if (user.is_logged && !isUserOptInValid) {
					setModalRedirectComplementAccountIsOpen(true);
				} else if (user.optin_comunication === "null") {
					setModalOptionComunication(true);
				} else if (user.is_logged && isUserOptInValid) {
					try {
						await handleRedirectCatalog("");
					} catch (error: any) {
						console.error(">>>> error", error)
						if (error.response.status === 401) {
							setShowModalLogout(true)
						}
					}
				}
			},
			hide: !user.is_logged,
			link: "/resgatar",
			title: "Resgatar",
		},
	];

	const accountDetails = [
		{
			customEvent: async (): Promise<void> =>
				await handleRedirectCatalog("my-orders?days=30"),
			title: "Minha Conta",
		},
		{
			customEvent: async (): Promise<void> =>
				await handleRedirectCatalog("extract"),
			title: "Extrato Atual",
		},
		{
			customEvent: (): void => {
				window.location.pathname = "/minhas-culturas";
			},
			title: "Minhas Culturas "
		},
		{
			customEvent: (): void => {
				window.location.pathname = "/extrato";
			},
			title: `Extrato Migrado`,
		},
		{
			customEvent: (): void => {
				window.location.pathname = "/relacionamentos";
			},
			title: `Contas Agregadas`,
		},
		{
			customEvent: (): void => {
				window.location.replace("/logout");
			},
			title: "Sair",
		},
	];

	const handleCloseModal = (): void => {
		setModalRedirectComplementAccountIsOpen(false);
	};

	const loadAttributes = (menuItem: {
		alternativeLink?: string;
		link: string;
	}) => {
		return (
			window.location.pathname === menuItem.link ||
			window.location.pathname === menuItem.alternativeLink
		);
	};

	return (
		<>
			<ModalLogout isOpen={showModalLogout} />
			{!hideFirstRowHeader && (
				<HeaderStyled.Container
					backgroundColor={backgroundColor}
					hideFirstRowHeader={hideFirstRowHeader}
				>
					<HeaderStyled.LogoLink title="Ao clicar neste logo você será redirecionado(a) para o catálogo de pontos do conecta">
						<a onClick={() => handleRedirectCatalog("")}>
							<HeaderStyled.LogoHeader
								alt={"Logo do Conecta Pontos"}
								src={HeaderImages.logoConectaPontos}
							/>
						</a>
					</HeaderStyled.LogoLink>
					<HeaderStyled.UserBoxContainer>
						<HeaderStyled.SecondChildNavFixed>
							<HeaderStyled.LogoUser
								src={HeaderImages.logoUser}
								alt="Ícone de usuário no cabeçalho da página"
							/>
							<HeaderStyled.LoginOrNewAccountLabel
								onClick={() => {
									if (user.is_logged) {
										setshowUserMenu(!showUserMenu);
									} else {
										setQueryParamValue("hotsite");
										setModalUserIsNotLogged(true);
									}
								}}
							>
								{user.is_logged ? (
									<>
										Olá, {user.name?.split(" ")[0]} <br />
										Sua conta
									</>
								) : (
									<>
										<strong>Entre </strong> ou <br />
										<strong>Cadastre-se</strong>
									</>
								)}
							</HeaderStyled.LoginOrNewAccountLabel>
						</HeaderStyled.SecondChildNavFixed>
						<HeaderStyled.SubMenuHeaderContainer
							id="subMenuHeader"
							showMenu={showUserMenu}
						>
							<HeaderStyled.SubMenuHeaderUserBalanceContainer>
								<HeaderStyled.SubMenuHeaderUserBalanceContainerText>
									<HeaderStyled.SubMenuHeaderText>
										Você tem
									</HeaderStyled.SubMenuHeaderText>
									<HeaderStyled.SubMenuHeaderText
										colorText={colors.primary.cian}
										fontSize={"22.5px"}
										textWeight={"800"}
									>
										{user.points ? Math.floor(user.points).toLocaleString("pt-BR")  + " pontos" : "0 pontos"}
									</HeaderStyled.SubMenuHeaderText>
									<HeaderStyled.SubMenuHeaderText>
										disponíveis para resgate
									</HeaderStyled.SubMenuHeaderText>
								</HeaderStyled.SubMenuHeaderUserBalanceContainerText>
								<HeaderStyled.SubMenuHeaderRedirectButton
									onClick={() => {
										if (
											user.is_logged &&
											(!user.agrega_opt_in_conecta_ag ||
												!user.agrega_opt_in_score)
										) {
											setModalRedirectComplementAccountIsOpen(true);
										} else if (user.optin_comunication === "null") {
											setModalOptionComunication(true);
										} else {
											httpRegistrationRedirect
												.getUrlRedirect({
													data: {
														documentNumber: user.document_number as string,
														email: user.email as string,
													},
												})
												.then((log) => {
													if (log.statusCode === 401) {
														setShowModalLogout(true)
													} else {
														window.open(log.response.data, "_self");
													}
												});
										}
									}}
								>
									&gt;
								</HeaderStyled.SubMenuHeaderRedirectButton>
							</HeaderStyled.SubMenuHeaderUserBalanceContainer>
							<HeaderStyled.SubMenuHeaderContainerList>
								<HeaderStyled.SubMenuHeaderList>
									{accountDetails.map((subMenuItems, index: number) => (
										<HeaderStyled.SubMenuHeaderListItem key={index}>
											<HeaderStyled.SubMenuHeaderListLink
												onClick={() => subMenuItems.customEvent()}
											>
												{subMenuItems.title}
											</HeaderStyled.SubMenuHeaderListLink>
										</HeaderStyled.SubMenuHeaderListItem>
									))}
								</HeaderStyled.SubMenuHeaderList>
							</HeaderStyled.SubMenuHeaderContainerList>
						</HeaderStyled.SubMenuHeaderContainer>
					</HeaderStyled.UserBoxContainer>
				</HeaderStyled.Container>
			)}

			<HeaderStyled.NavFixed
				showSubItems={showSubItemsMenu}
				hideFirstRowHeader={hideFirstRowHeader}
				style={{zIndex: 1}}
			>
				{isMobile ? (
					<>	
						{!showSubItemsMenu && (
							<HeaderStyled.ListItem
							title={"Resgatar"}
							isActive={window.location.pathname === "/resgatar"}
							customLink={true}
							style={{ alignSelf: 'center' }}
						>
							<a onClick={() => handleRedirectCatalog("")}>
								Resgatar
							</a>
						</HeaderStyled.ListItem>
						)}
						<HeaderStyled.ContainerNavBar>
							<HeaderStyled.ContainerLoginIntems>
								<HeaderStyled.MenuHamburgerMobile
									onClick={() => setShowSubItemsMenu(!showSubItemsMenu)}
								/>
							</HeaderStyled.ContainerLoginIntems>
						</HeaderStyled.ContainerNavBar>

						{showSubItemsMenu && (
							<div>
								<a href="/">
									<HeaderStyled.HomeIconCustomizedMobile />
								</a>
								<HeaderStyled.NavItemsListMobile>
									{menuItems.map((menuItem, index: number) => {
										if (menuItem.hide) {
											return <Fragment key={index} />;
										} else {
											return (
												<HeaderStyled.ListItemMobile
													key={index}
													title={menuItem.title}
													isActive={loadAttributes(menuItem)}
													customLink={menuItem.title.toLowerCase() === 'resgatar'}
												>
													<a onClick={() => menuItem.customEvent(user)}>
														{menuItem.title}
													</a>
												</HeaderStyled.ListItemMobile>
											);
										}
									})}
								</HeaderStyled.NavItemsListMobile>
							</div>
						)}
					</>
				) : (
					<>
						<HeaderStyled.FirstChildNavFixed>
							<div>
								<HeaderStyled.LinkNav>
									<HeaderStyled.LogoNav
										src={HeaderImages.logoAgrega}
										alt={"Logo do AGREGA"}
									/>
								</HeaderStyled.LinkNav>
							</div>
							<div>
								<HeaderStyled.NavItemsList>
									<a href="/">
										<HeaderStyled.HomeIconCustomized />
									</a>
									{menuItems.map((menuItem, index: number) => {
										if (menuItem.hide) {
											return <Fragment key={index} />;
										} else {
											return (
												<HeaderStyled.ListItem
													key={index}
													title={menuItem.title}
													isActive={loadAttributes(menuItem)}
													customLink={menuItem.title.toLowerCase() === 'resgatar'}
												>
													<a onClick={() => menuItem.customEvent(user)}>
														{menuItem.title}
													</a>
												</HeaderStyled.ListItem>
											);
										}
									})}
								</HeaderStyled.NavItemsList>
							</div>
						</HeaderStyled.FirstChildNavFixed>
					</>
				)}
			</HeaderStyled.NavFixed>

			<ModalRedirectComplementAccount
				isOpen={modalRedirectComplementAccountIsOpen}
				handleCloseModal={handleCloseModal}
			/>

			<ModalUserIsNotLogged
				queryParamValue={queryParamValue}
				isOpen={modalUserIsNotLogged}
				handleCloseModal={() => setModalUserIsNotLogged(false)}
			/>

			<OptinComunication
				isOpen={modalOptionComunication}
				handleCloseModal={() => setModalOptionComunication(false)}
			/>
		</>
	);
};

export default Header;
