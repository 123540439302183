import logoConectaAg from './conecta-ag.svg'
import logoAgrega from './logo-agrega.png'
import logoConectaPontos from './conecta-pontos-logo.png'
import logoUser from './logo-user.svg'


export const HeaderImages = {
    logoAgrega,
    logoConectaAg,
    logoConectaPontos,
    logoUser
}