import { FC } from "react";
import { InputStyled } from "./styled";
import { InputProps, InputStyledProps } from "../../../../design-system/domain/components";

const Input: FC<InputProps & InputStyledProps> = ({
  autoComplete,
  autoFocus,
  border,
  className,
  disabled,
  maxChar,
  maxCharLenght,
  minChar,
  placeholder,
  required,
  type,
  width,
  value,
  onChange,
}) => {
  return (
    <>
      {onChange ? (
        <InputStyled.InputCustomized
          autoComplete={autoComplete}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
          border={border}
          className={className}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          min={minChar}
          max={maxChar}
          maxLength={maxCharLenght}
          required={required}
          type={type}
          value={value}
          width={width}
        />
      ) : (
        <InputStyled.InputCustomized
          autoComplete={autoComplete}
          border={border}
          className={className}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          min={minChar}
          max={maxChar}
          maxLength={maxCharLenght}
          required={required}
          type={type}
          width={width}
          value={value}
        />
      )}
    </>
  );
};

export default Input;
