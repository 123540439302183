import { createGlobalStyle } from 'styled-components'
import Poppins from "./fonts/Poppins-SemiBold.ttf"
import PoppinsMedium from "./fonts/Poppins-Medium.ttf"

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Poppins';
        src: url(${Poppins});
    }
    
    @font-face {
        font-family: 'PoppinsMedium';
        src: url(${PoppinsMedium});
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    ul {
        list-style-type: none;
    }

    body { 
        font-family: 'Helvetica', 'Arial', sans-serif;
        margin: 0;
        outline: none;
    }

    * {
        outline: none;
        margin: 0;
        padding: 0;
    }
`