export const BARCODE_MASK = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
];

export const BARCODE_LENGTH = 44;
