/* eslint-disable @typescript-eslint/member-ordering */
import { JwtAdapter } from "../../../infra/jwt-adapter";
import { IJWTDecode } from "../../../data/protocols/utils/jwt-decode";

export class JWTDecode implements IJWTDecode {
  private jwtAdapter = new JwtAdapter();

  decode(token: string): JwtAdapter.DecodeResult {
    return this.jwtAdapter.customDecode(token);
  }
}