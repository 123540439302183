import { breakpoints, colors } from "design-system/theme";
import styled from "styled-components";

const Container = styled.div``

const ContainerSubTitleText = styled.section`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    max-width: 1000px;
    max-height: 100%;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    
    padding: 0 0 50px 75px;   

    ${breakpoints.up('lg')} {
        padding: 0 0 50px 175px;   
    }
    ${breakpoints.down('md')} {
        padding: 0 20px 20px;
    }
`;
const Message = styled.p`
    padding: 20px 20px 20px 150px;
    background-color: ${colors.neutral.gray04};
`

const SubTitleText = styled.p`
    color: ${colors.neutral.gray03};
    display: block;
    font-size: 16px;
    line-height: 1.3;
    width: 100%;

    ${breakpoints.down("sm")} {
        font-size: 17px;
        width: 75%;
        margin-top: -25px;
    }
`;


const RowEmptyData = styled.div`
    color: ${colors.status.error2};    
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 100%;
    padding: 50px 150px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    background-color: ${colors.generics.grayLight};

    ${breakpoints.down(1280)} {
        padding: 50px 20px;
    }

    ${breakpoints.down('sm')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        margin: 0;
    }
`
export const ProviderPageStyled = {
    Container,
    ContainerSubTitleText,
    Message,
    RowEmptyData,
    SubTitleText,
}