import styled from 'styled-components'
import { Button } from '../../../design-system/components'
import { colors } from '../../../design-system/theme/colors'
import { CloseIcon } from '../../../design-system/icons'

const CloseButtom = styled.a`
position: absolute ;
right: 20px;
top: 20px;
font-size: 30px;
cursor: pointer;
`

const Title = styled.h2`
  color: ${colors.neutral.gray10};
`
const SubTitleSite = styled.span`
  color: ${colors.primary.orange02};
  font-weight: 800;
  text-decoration: underline;
`

const HeaderRow = styled.div`
  display: flex;
  height: 35px;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 5px 25px;
`;

const LogoHeaderModal = styled.img`
    max-width: 150px;
`

const LinksSteps = styled.a`
  color: ${colors.primary.orange01};
  text-decoration: underline;
`

const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
`

const CustomCancelButton = styled(Button)`
    background-color: ${colors.generics.grayLight};
    color: ${colors.generics.darkBlue};

    &:hover{
         color: ${colors.generics.white};
    }
`

const CustomConfirmButton = styled(Button)`
    background-color: ${colors.primary.red};
    border-radius: 35px;
    color: ${colors.generics.white};
    cursor: pointer;
    outline: 0;
    width: 80%;

    &:hover {
      background-color: ${colors.primary.red};
    }
`

const ModalBody = styled.div`
  display: block;
  margin-top: 20px;
  padding: 10px 25px;
`;


const RowButton = styled.div`
  margin-top: 35px;
  text-align: center;
`

const ListInstructions = styled.ul`
  list-style-type: disc;
  margin-bottom: 10px;
  padding: 0 30px;
`

const BoxAgrega = styled.div`
    margin-top: 40px;

    .link-redirect {
      color: ${colors.primary.orange02};
      font-weight: 600;
      text-decoration: underline;
    }
    .logo {
      height: 18px;
    }

    .description {
      color: ${colors.neutral.gray10};
      margin-top: 15px;
      padding: 0 8px;
    }
`

const CloseIconCustom = styled(CloseIcon)`
    color: ${colors.primary.orange02};
`


export const UserNotLoggedStyled = {
  BoxAgrega,
  CloseButtom,
  CloseIconCustom,
  CustomCancelButton,
  CustomConfirmButton,
  HeaderRow,
  LinksSteps,
  ListInstructions,
  LogoHeaderModal,
  ModalBody,
  ModalFooter,
  RowButton,
  SubTitleSite,
  Title
}