export class DateHelper {
	/**
	 * @description
	 * Convert YYYY-MM-DD to DD-MM-YYYY
	 */
	convertAmericanFormatToBrazilFormat(date: string): string {
		const [year, month, day] = date.split("-");
		return `${day}-${month}-${year}`;
	}

	convertAmericanFormatToBrazilFormatWithBars(date: string): string {
		const [year, month, day] = date.split("-");
		return `${day}/${month}/${year}`;
	}
}
