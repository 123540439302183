import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { isMobile } from "react-device-detect";
import TapumeVoltoLogoSite from 'Tapume-Volto-Logo-Site.png';
import TapumeVoltoLogoSiteMobile from 'Tapume-Volto-Logo-Site-Mobile.png';
import TapumeVoltoLogoCatalogo from 'Tapume-Volto-Logo-Catalogo.png.png'
import TapumeVoltoLogoCatalogoMobile from 'Tapume-Volto-Logo-Catalogo-Mobile.png.png'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {isMobile ? (
      <>
      {process.env.REACT_APP_TAPUME_SITE === "1" && (
        <img src={TapumeVoltoLogoSiteMobile} alt="Site indisponível" style={{ height: 'auto', width: '100%'}} />
      )}

      {process.env.REACT_APP_TAPUME_CATALOGO === "1" && (
        <img src={TapumeVoltoLogoCatalogoMobile} alt="Catálogo indisponível" style={{ height: 'auto', width: '100%'}} />
      )}
      </>
    ) : (
      <>
      {process.env.REACT_APP_TAPUME_SITE === "1" && (
        <img src={TapumeVoltoLogoSite} alt="Site indisponível" style={{ height: 'auto', width: '100%'}} />
      )}

      {process.env.REACT_APP_TAPUME_CATALOGO === "1" && (
        <img src={TapumeVoltoLogoCatalogo} alt="Catálogo indisponível" style={{ height: 'auto', width: '100%'}} />
      )}
      </>
    )}

    {process.env.REACT_APP_TAPUME_CATALOGO !== "1" && process.env.REACT_APP_TAPUME_SITE !== "1" && (
      <App />
    )}
  </React.StrictMode>
);