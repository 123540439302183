import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Http } from "../../api";
import { StorageHelper } from "../../utils/functions/storage/storage";
import { HttpRegistrationRedirect } from "../../services/account-user/redirect";
import { useState } from "react";

export const useRedirectCatalog = () => {
    const [modalOptionComunication, setModalOptionComunication] = useState<boolean>(false);
    const [modalRedirectComplementAccountIsOpen, setModalRedirectComplementAccountIsOpen] = useState<boolean>(false);
    const [modalUserIsNotLogged, setModalUserIsNotLogged] = useState<boolean>(false);
    const [queryParamValue, setQueryParamValue] = useState<string>("");
    const [showModalLogout, setShowModalLogout] = useState<boolean>(false);

    const { user } = useSelector((state: RootState) => state);
    const http = new Http();
    const storageHelper = new StorageHelper();
    
    const httpRegistrationRedirect = new HttpRegistrationRedirect(
        http,
        storageHelper
    );
    
    const handleRedirectCatalog = async (routeCatalog: string) => {
        if (!user.is_logged) {
            setQueryParamValue("hotsite");
            setModalUserIsNotLogged(true);
    
            return;
        }
        const userWithOptInPending =
            !user.agrega_opt_in_conecta_ag || !user.agrega_opt_in_score;
    
        if (user.is_logged && userWithOptInPending) {
            setModalRedirectComplementAccountIsOpen(true);
        } else if (user.optin_comunication === "null") {
            setModalOptionComunication(true);
        } else {
            const log = await httpRegistrationRedirect.getUrlRedirect({
                data: {
                    documentNumber: user.document_number as string,
                    email: user.email as string,
                    routeCatalog,
                },
            });
    
            if (log.statusCode >= 500) {
                toast.error('Falha ao redirecionar para o catálogo!', {
                    autoClose: 5000,
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
    
            if (log.statusCode === 401) {
                setShowModalLogout(true)
            } else {
                window.open(log.response.data, "_self");
            }
        }
    };
    
    return {
        handleRedirectCatalog,
        modalOptionComunication,
        modalRedirectComplementAccountIsOpen,
        modalUserIsNotLogged,
        queryParamValue,
        setModalOptionComunication,
        setModalRedirectComplementAccountIsOpen,
        setModalUserIsNotLogged,
        setQueryParamValue,
        setShowModalLogout,
        showModalLogout,
    };

}