import { FC } from "react";
import { useTitle } from "hooks";
import {
  ContainerPage,
  StackCustomized,
  TitlePage,
} from "design-system/components";
import { ProductPageStyled } from "./styled";

const ProductPage: FC = () => {
  useTitle("Produtos");
  return (
    <>
      <ContainerPage>
        <TitlePage title="Produtos Elegíveis" />
        <ProductPageStyled.ContainerSubTitleText>
          <ProductPageStyled.SubTitleText>
            Confira aqui os produtos participantes do Programa AGREGA assim como o acúmulo de pontos a cada Real (R$) investido neles.
          </ProductPageStyled.SubTitleText>
        </ProductPageStyled.ContainerSubTitleText>
        <StackCustomized />
      </ContainerPage>
    </>
  );
};

export default ProductPage;
