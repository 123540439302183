import { FC } from "react";
import { CloseIcon } from "../../../design-system/icons";
import { Modal } from "../../../design-system/components";
import { RedirectComplementAccountStyled } from "./styled";

export type ModalRedirectComplementAccountProps = {
  handleCloseModal: () => void;
  isOpen: boolean;
};
const ModalRedirectComplementAccount: FC<ModalRedirectComplementAccountProps> = ({
  handleCloseModal,
  isOpen,
}) => {
  return (
    <Modal
      header={
        <RedirectComplementAccountStyled.HeaderRow>
          <RedirectComplementAccountStyled.CloseButtom onClick={() => handleCloseModal()}>
            <CloseIcon/>
          </RedirectComplementAccountStyled.CloseButtom>
        </RedirectComplementAccountStyled.HeaderRow>
      }
      content={
        <>
          <div>
            <RedirectComplementAccountStyled.ModalBody>
              <div>
                <p>
                  Para participar do programa AGREGA você deve complementar o
                  seu cadastro.
                </p>
              </div>
              <RedirectComplementAccountStyled.RowButton>
                <RedirectComplementAccountStyled.CustomConfirmButton
                  buttonText="Desejo continuar"
                  eventClick={() => window.location.replace("/cadastro")}
                />
              </RedirectComplementAccountStyled.RowButton>
            </RedirectComplementAccountStyled.ModalBody>
          </div>
        </>
      }
      titleAlign={`center`}
      open={isOpen}
      size={`SMALL`}
    />
  );
};

export default ModalRedirectComplementAccount;
