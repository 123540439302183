/* eslint-disable @typescript-eslint/member-ordering */
import { IGetAllCultures } from "../../data/protocols/services/culture/get-all";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";
import { StorageHelper } from "../../utils/functions/storage/storage";

export class HttpGetAllCulturesList implements IGetAllCultures {
  constructor(
    private readonly httpClient: HttpClient<
      IGetAllCultures.Params,
      IGetAllCultures.HttpResponse
    >,
    private readonly storageHelper: StorageHelper
  ) { }

  private headerSubscriptionKey = {
    Authorization: `Bearer ${this.storageHelper.get('token')}`,
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
    ["X-Session-Id"]: this.storageHelper.get("sessionKey"),


  };

  async getAll(): Promise<IGetAllCultures.Result> {
    const result = await this.httpClient.request({
      headers: this.headerSubscriptionKey,
      method: HttpMethods.GET,
      url: `${process.env.REACT_APP_BASE_URL_API}participant/culture/cultures`,
    });

    return {
      response: result?.response.data.rows,
      statusCode: result?.statusCode,
      success: result?.success,
    } as IGetAllCultures.Result;
  }
}
