import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthPageStyled } from "./styled";
import { capitalizeWords, JWTDecode, StatusRequest } from "utils";
import { Http } from "api";
import { HttpAuthCheckToken } from "services/auth/check-token";
import { useTitle } from "hooks";
import { populateUser, setOptComunication, setScore } from "store/slices/user";
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpGetUserScore } from "services/account-user/score";
import { checkIfStringIsValidBoolean } from "utils/functions/generics/compare-boolean";
import ModalLogout from "modal/modal-logout";
import { useRedirectCatalog } from "design-system/hooks/useRedirectCatalog";

const AuthPage: FC = () => {
	const [statusTokenValidation, setStatusTokenValidation] = useState<StatusRequest>(StatusRequest.FETCHING);
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);

	const originUrl = document.referrer;
	const originUrlRedirectCatalog = `${process.env.REACT_APP_URL_REDIRECT_CATALOG}`;

	const urlParams = new URLSearchParams(useLocation().search);

	const storageHelper = new StorageHelper();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { handleRedirectCatalog } = useRedirectCatalog();

	useTitle("SSO - Análise");

	const handleToken = async (): Promise<void> => {
		try {
			const accessToken = urlParams.get("accessToken");
			const sessionId = urlParams.get("sessionId");

			if (!accessToken) {
				throw "Token not provided";
			}

			const http = new Http();
			const jWTDecode = new JWTDecode();
			const httpAuthCheckToken = new HttpAuthCheckToken(http, jWTDecode);
			const httpGetUserScore = new HttpGetUserScore(http);

			const { success, userInfo } = await httpAuthCheckToken.check({
				accessToken,
			});
			if (!success) {
				setStatusTokenValidation(StatusRequest.ERROR);
				return;
			}
			const {
				documentNumber,
				optInPoints,
				optInAgrega,
				optinTermsAndPrivacy,
				optinComunication,
				terrena,
				...rest
			} = userInfo;

			storageHelper.set<string>("token", String(userInfo.token));
			storageHelper.set<string>("sessionKey", String(sessionId));
			storageHelper.set<string>("acceptedTerms", String(userInfo.optinTermsAndPrivacy));
			storageHelper.set<string>("acceptedTermsAgrega", String(userInfo.optInAgrega));

			new Promise((resolve) => {
				resolve(
					dispatch(
						populateUser({
							...rest,
							agrega_opt_in_conecta_ag:
								checkIfStringIsValidBoolean(optInAgrega),
							agrega_opt_in_opt_in_terms_and_privacy:
								checkIfStringIsValidBoolean(optinTermsAndPrivacy),
							agrega_opt_in_score: checkIfStringIsValidBoolean(optInPoints),
							document_number: documentNumber,
							is_logged: true,
							modal_check_your_data_already_show_this_session: false,
							name: capitalizeWords(String(userInfo.name)),
							optin_comunication: optinComunication,
							terrena: checkIfStringIsValidBoolean(terrena),
						})
					)
				);
			}).then(() => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				dispatch(setOptComunication(optinComunication as any));
				httpGetUserScore
					.getScoreByUser({
						data: {
							documentNumber: String(userInfo.documentNumber),
							token: String(userInfo.token),
							xSessionId: String(sessionId),
						},
					})
					.then((result: { pointsValue: number; statusCode: number }) => {
						if (result?.statusCode === 401) {
							setShowModalLogout(true);
						} else {
							dispatch(
								setScore(
									!result?.pointsValue || isNaN(result?.pointsValue)
										? 0
										: result?.pointsValue
								)
							);

							setTimeout(() => {
								navigate("/");
							}, 3000);
						}
					})
					.catch(() => {
						dispatch(setScore(0));
						setTimeout(() => {
							navigate("/");
						}, 3000);
					});
			});

			setStatusTokenValidation(StatusRequest.SUCCESS);
		} catch (error) {
			setStatusTokenValidation(StatusRequest.ERROR);
		}
	};

	useEffect(() => {
		const acceptedTerms = storageHelper.get<string>("acceptedTerms");
		const acceptedTermsAgrega = storageHelper.get<string>("acceptedTermsAgrega");

		if (statusTokenValidation === StatusRequest.FETCHING) {
			handleToken();
		}
		if (statusTokenValidation === StatusRequest.SUCCESS) {
			if ((acceptedTerms === "true" && acceptedTermsAgrega === "true") && (originUrl === originUrlRedirectCatalog)) {
				handleRedirectCatalog("");
			}
			return
		}
	}, [statusTokenValidation]);

	const defineContent = () => {
		switch (statusTokenValidation) {
			case StatusRequest.FETCHING:
				return (
					<AuthPageStyled.BoxLoading>
						<AuthPageStyled.CircularLoadingCustomized />
					</AuthPageStyled.BoxLoading>
				);
			case StatusRequest.ERROR:
				return (
					<>
						<AuthPageStyled.LabelCheckTokenStatus>
							Ocorreu um erro em seu acesso. <br />
							<br /> Tente novamente ou entre em contato com nossa central de
							atendimento clicando{" "}
							<a
								style={{ color: "orange" }}
								href={process.env.REACT_APP_CONECTA_AG_URL + "/contact"}
							>
								aqui
							</a>
							.
						</AuthPageStyled.LabelCheckTokenStatus>
						<AuthPageStyled.ErrorIconCustomized />
					</>
				);
		}
	};
	return (
		<>
			<ModalLogout isOpen={showModalLogout} />
			<AuthPageStyled.Container>{defineContent()}</AuthPageStyled.Container>
		</>
	);
};

export default AuthPage;
