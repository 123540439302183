import styled from "styled-components";
import { ContainerScrollProps } from '../../../design-system/domain'
const Container = styled.section`
    /* margin-top: 180px; */
`
const ContainerScroll = styled.section<ContainerScrollProps>`
    width: 100%;
    max-height: ${props => props.height ? props.height + 'px' : 'auto'};
    overflow: auto;
    margin-top: 20px ;
`

export const SectionContainerStyled = {
    Container,
    ContainerScroll,
}