import { FC, Fragment, useEffect, useState } from "react";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { HomeImages } from "design-system/images/home";
import { HomePageStyled } from "./styled";
import { useTitle, useScrollToTop } from "hooks";
import { setModalCheckYourDataShowThisSessionAsTrue } from "store/slices/user";

const isFirstAccessOfDay = () => {
	const lastAccessDate = localStorage.getItem('lastAccessDate');
	const currentDate = new Date();
	const formattedCurrentDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

	if (!lastAccessDate || lastAccessDate !== formattedCurrentDate){
		localStorage.setItem('lastAccessDate', formattedCurrentDate);
		return true;
	}
	return false;
};

const HomePage: FC = () => {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const { user } = useSelector((state: RootState) => state);
	const dispatch = useDispatch();

	useTitle("Home");
	useScrollToTop();

	useEffect(() => {
		if (isFirstAccessOfDay()) {
			setModalIsOpen(true);
		}
	}, []);
	
	useEffect(() => {
        (function(c: any, l: Document, a: string, r: string, i: string, y?: Element | null){
            c[a] = c[a] || function(...args: any[][]){(c[a].q = c[a].q || []).push(args)};
            const t = l.createElement(r) as HTMLScriptElement;
            t.async = true;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            if (y && y.parentNode) {
                y.parentNode.insertBefore(t, y);
            }
        })(window, document, "clarity", "script", "mm69rd7xp9");
    }, []);

	const handleCloseModal = () => {
		setModalIsOpen(false);
		dispatch(setModalCheckYourDataShowThisSessionAsTrue());
	};
	
	return (
		<Fragment>
			<Modal
				afterClose={handleCloseModal}
				onCancel={handleCloseModal}
				width={"100%"}
				style={{ maxWidth: "360px" }}
				zIndex={10000000000}
				title="SEUS DADOS ESTÃO ATUALIZADOS?"
				open={
					modalIsOpen &&
					user.is_logged &&
					!user.modal_check_your_data_already_show_this_session
				}
				footer={
					<HomePageStyled.ModalFooter>
						<button
							className="see-now"
							onClick={() =>
								window.open(
									`${process.env.REACT_APP_CONECTA_AG_URL}/customer/account`,
									"_self"
								)
							}
						>
							Revisar agora
						</button>
						<button className="see-later" onClick={handleCloseModal}>
							Conferir depois
						</button>
					</HomePageStyled.ModalFooter>
				}
			>
				<HomePageStyled.ModalContent>
					<p className="modal-content-text">
						Seus dados atualizados garantem a sua segurança e melhor
						experiência. Aproveite para revisar seus dados clicando no botão
						abaixo!
					</p>
				</HomePageStyled.ModalContent>
			</Modal>
			<HomePageStyled.FirstSectionContainer>
				<HomePageStyled.BoxTitle>
					<HomePageStyled.FirstSectionTitle>
						Agora o AGREGA faz parte do{" "}
						<p className="company-name">conecta pontos</p>
					</HomePageStyled.FirstSectionTitle>
					<HomePageStyled.Subtitle>
						Fique tranquilo(a), estamos aqui para te explicar tudo o que está
						acontecendo!
					</HomePageStyled.Subtitle>
				</HomePageStyled.BoxTitle>
				<div>
					<HomePageStyled.BannerHome
						src={HomeImages.bannerHome}
						alt="Banner contendo um trator em um campo agrario"
					/>
				</div>
			</HomePageStyled.FirstSectionContainer>
			<HomePageStyled.SecondSectionContainer>
				<HomePageStyled.SecondSectionTitle>
					E o que é o conecta.ag e o conecta pontos?
				</HomePageStyled.SecondSectionTitle>
				<HomePageStyled.SecondSectionProgram>
					<div className="box-program-description">
						<img
							className="logo-img"
							src={HomeImages.logoConectaAgHome}
							alt="Logo conecta AG"
						/>
						<p className="program-description">
							<b> Nós, do conecta.ag, somos um ecossistema</b> que nasceu para
							endereçar todos os seus desafios antes, durante e depois da safra,
							com o objetivo de tornar o seu negócio ainda mais produtivo e
							competitivo.
						</p>
					</div>

					<div className="box-program-description">
						<img
							className="logo-img"
							src={HomeImages.logoConectaPontos}
							alt="Logo conecta pontos"
						/>
						<p className="program-description">
							<b>O conecta pontos é a plataforma </b>que oferece oportunidades
							aos Agricultores para ganhar pontos que poderão ser trocados por
							produtos ou serviços para o seu negócio e família, através das
							nossas empresas parceiras e canais de distribuição.
						</p>
					</div>
				</HomePageStyled.SecondSectionProgram>
			</HomePageStyled.SecondSectionContainer>
			<HomePageStyled.ThirdSectionContainer>
				<div className="box-common-we">
					<HomePageStyled.ThirdSectionTitle>
						Já é cadastrado no AGREGA?
					</HomePageStyled.ThirdSectionTitle>

					<HomePageStyled.ThirdSectionFirstParagraph>
						<b>
							Então venha conosco e saiba o que mudou e confira todas as
							novidades para melhorar a sua experiência!
						</b>
					</HomePageStyled.ThirdSectionFirstParagraph>

					<HomePageStyled.ThirdSectionSecondParagraph>
						Se você já faz parte do programa AGREGA, não se preocupe porque seu
						cadastro e seu saldo de pontos já foram migrados para o conecta
						pontos, você só precisa seguir os próximos passos para ativá-lo e
						usufruir dos seus benefícios.
					</HomePageStyled.ThirdSectionSecondParagraph>
				</div>
				<div>
					<HomePageStyled.WomanAgroBusinessImg
						src={HomeImages.agribusinessWoman}
						alt="Mulher em um campo de trigo olhando a paisagem"
					/>
				</div>
			</HomePageStyled.ThirdSectionContainer>

			<HomePageStyled.FourthSection>
				<HomePageStyled.BoxImg>
					<img
						src={HomeImages.computerAccess}
						alt="Computador com site Conecta"
					/>
				</HomePageStyled.BoxImg>
				<HomePageStyled.HowIAccessBox>
					<img src={HomeImages.squareWhite} alt="Quadrados brancos" />
					<p className="title">E agora, como eu acesso?</p>

					<ul>
						<li>
							Agora o seu acesso será através do conecta.ag, e no primeiro
							acesso, será necessário redefinir sua senha, clicando na opção{" "}
							<b>"Esqueci a senha"</b>.
						</li>
						<li>
							Após cadastrar a sua <b>nova senha, realize o seu login</b> e faça
							a adesão do Programa com um aceite no novo regulamento e nos
							termos de política de privacidade do AGREGA.
						</li>
						<li>
							Pronto! Nas próximas vezes, poderá acessar o Programa AGREGA com
							sua <b>nova senha.</b>
						</li>
					</ul>

					<div
						onClick={() =>
							window.open(
								`${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/login?redirect=hotsite`,
								"_self"
							)
						}
						className="row-access-conecta"
					>
						<button>Acesse o conecta pontos</button>
					</div>
				</HomePageStyled.HowIAccessBox>
			</HomePageStyled.FourthSection>
			<HomePageStyled.FifthSessionContainer>
				<HomePageStyled.FifthSessionTitle>
					Veja abaixo o que mudou e os novos{" "}
				</HomePageStyled.FifthSessionTitle>

				<HomePageStyled.RowComparisons>
					<HomePageStyled.RowLogos>
						<div id="logo-ag">
							<img
								className="logo-version"
								src={HomeImages.labelOld}
								alt="Forma preto e branco com a palavra antigo"
							/>
							<img
								className="logo-img-fifth-session"
								src={HomeImages.logoConectaAGLabel}
								alt="Logo conecta.ag"
							/>
						</div>
						<div id="logo-pontos">
							<img
								className="logo-version"
								src={HomeImages.labelNew}
								alt="Forma laranja branco com a palavra novo"
							/>
							<img
								className="logo-img-fifth-session"
								src={HomeImages.logoConectaPontosLabel}
								alt="Logo conecta pontos"
							/>
						</div>
					</HomePageStyled.RowLogos>
				</HomePageStyled.RowComparisons>
				<HomePageStyled.RowFeatures>
					<div className="features-ag">
						<p>Catálogo com 23 mil opções de produtos e serviços</p>
					</div>
					<div className="features-score">
						<p>
							Catálogo com <b>mais de 270 mil opções</b> de produtos e serviços
						</p>
					</div>
				</HomePageStyled.RowFeatures>

				<HomePageStyled.RowFeatures>
					<div className="features-ag">
						<p>Resgate apenas com pontos</p>
					</div>
					<div className="features-score">
						<p>
							Resgate também com <b>pontos + dinheiro</b>
						</p>
					</div>
				</HomePageStyled.RowFeatures>

				<HomePageStyled.RowFeatures>
					<div className="features-ag">
						<p>
							Resgates com maior tempo de espera e sem acompanhamento do pedido
						</p>
					</div>
					<div className="features-score">
						<p>
							<b>Maior agilidade e acompanhamento</b> nos resgates
						</p>
					</div>
				</HomePageStyled.RowFeatures>

				<HomePageStyled.RowFeatures>
					<div className="features-ag">
						<p>
							{" "}
							Agregação de até 5 CPFs e CNPJs para acúmulo de pontos em uma
							única conta{" "}
						</p>
					</div>
					<div className="features-score">
						<p>
							Agregação <b>sem limites de quantidade de CPFs e CNPJs</b> para
							acúmulo de pontos em uma única conta
						</p>
					</div>
				</HomePageStyled.RowFeatures>

				<HomePageStyled.RowFeatures>
					<div className="features-ag">
						<p>
							Acúmulo de pontos apenas nos distribuidores cadastrados no AGREGA
						</p>
					</div>
					<div className="features-score">
						<p>
							<b>Acúmulo de pontos também com outros parceiros </b>(Em breve)
						</p>
					</div>
				</HomePageStyled.RowFeatures>
			</HomePageStyled.FifthSessionContainer>
			<HomePageStyled.SixthSessionContainer>
				<HomePageStyled.RowSquare>
					<img src={HomeImages.squareWhite} alt="Três quadrados brancos" />
				</HomePageStyled.RowSquare>
				<p id="sixthSessionTitle">Ainda com dúvidas?</p>

				<p id="contact-us">
					Entre em contato, temos um canal exclusivo através do e-mail
					agrega@basf.com ou pelo telefone 0800-0192-500, de segunda a
					sexta-feira, das 8h às 19h.
				</p>
			</HomePageStyled.SixthSessionContainer>
			<HomePageStyled.SeventhContainer>
				<p id="title">Perguntas frequentes</p>

				<HomePageStyled.SeventhCardCommonQuestionsRow>
					<HomePageStyled.SeventhCardCommonQuestions>
						<h5 id="question-title">Como funciona o conecta pontos?</h5>

						<p id="answer">
							<b>O conecta pontos</b> é a plataforma que oferece oportunidades
							no dia a dia do produtor para ganhar pontos que poderão ser usados
							para trocar por soluções agrícolas, produtos para o seu negócio e
							família.
						</p>
					</HomePageStyled.SeventhCardCommonQuestions>

					<HomePageStyled.SeventhCardCommonQuestions>
						<h5 id="question-title">
							Como faço para participar do conecta pontos?
						</h5>

						<p id="answer">
							É simples! Basta se cadastrar em nosso site <b>conecta.ag</b>. O
							acesso à plataforma conecta pontos está disponível apenas para
							pessoas físicas e maiores de 18 anos.
						</p>
					</HomePageStyled.SeventhCardCommonQuestions>

					<HomePageStyled.SeventhCardCommonQuestions>
						<h5 id="question-title">Como ganhar pontos?</h5>

						<p id="answer">
							Ganhar pontos no programa é muito fácil! Basta comprar produtos
							elegíveis nos programas participantes de acordo com os respectivos
							regulamentos.
						</p>
					</HomePageStyled.SeventhCardCommonQuestions>
				</HomePageStyled.SeventhCardCommonQuestionsRow>
				<HomePageStyled.SeventhCardCommonQuestionsRow>
					<HomePageStyled.SeventhCardCommonQuestions>
						<h5 id="question-title">
							Quais são os canais distribuidores e programas parceiros que
							participam do programa?
						</h5>

						<p id="answer">
							Atualmente o programa participante do conecta pontos é o AGREGA.
							Em breve teremos novidades!
						</p>
					</HomePageStyled.SeventhCardCommonQuestions>

					<HomePageStyled.SeventhCardCommonQuestions>
						<h5 id="question-title">Tenho uma conta única para o meu CPF?</h5>

						<p id="answer">
							Sim, o conecta consolida todos os pontos acumulados nos programas
							participantes, que serão apresentados no Extrato do Participante
							no conecta pontos (webpremios.digital)
						</p>
					</HomePageStyled.SeventhCardCommonQuestions>

					<HomePageStyled.SeventhCardCommonQuestions>
						<h5 id="question-title">Como posso trocar meus pontos?</h5>

						<p id="answer">
							Os pontos acumulados poderão ser trocados por produtos ou serviços
							(chamamos de "Resgate de Pontos") de acordo com a quantidade de
							pontos necessária através no nosso catálogo de prêmios disponível
							no site.
						</p>
					</HomePageStyled.SeventhCardCommonQuestions>
				</HomePageStyled.SeventhCardCommonQuestionsRow>
				<HomePageStyled.SeventhSessionRowOrangeBoxes>
					<HomePageStyled.SeventhSessionOrangeBoxes
						onClick={() =>
							window.open(
								`${process.env.REACT_APP_CONECTA_AG_URL}/contato`,
								"_blank"
							)
						}
					>
						<HomePageStyled.IconQuestionOrangeBox />
						<p>
							{" "}
							Para mais informações, acesse nossa <b>Central de Ajuda</b>{" "}
						</p>
					</HomePageStyled.SeventhSessionOrangeBoxes>

					<HomePageStyled.SeventhSessionOrangeBoxes
						onClick={() => (window.location.href = "/regulamento")}
					>
						<HomePageStyled.IconPaperOrangeBox />
						<p>
							{" "}
							Para mais informações, leia nosso <b>Regulamento</b>{" "}
						</p>
					</HomePageStyled.SeventhSessionOrangeBoxes>

					<HomePageStyled.SeventhSessionOrangeBoxes
						onClick={() =>
							window.open(
								`${process.env.REACT_APP_CONECTA_AG_URL}/faq`,
								"_blank"
							)
						}
					>
						<HomePageStyled.IconQuestionOutlinedOrangeBox />
						<p>
							{" "}
							Para mais informações, confira nossas <b>
								Perguntas Frequentes
							</b>{" "}
						</p>
					</HomePageStyled.SeventhSessionOrangeBoxes>
				</HomePageStyled.SeventhSessionRowOrangeBoxes>
			</HomePageStyled.SeventhContainer>
		</Fragment>
	);
};

export default HomePage;
