import { breakpoints } from "./../../design-system/theme/breakpoints";
import styled from "styled-components";
import { colors } from "design-system/theme";

const ContainerSubTitleText = styled.section`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: center;
	max-width: 1000px;
	max-height: 100%;

	width: 100%;
`;

const SubTitleText = styled.p`
	color: ${colors.neutral.gray03};
	display: block;
	font-size: 16px;
	line-height: 1.5;
	margin-top: 20px;
	width: 700px;

	${breakpoints.down("sm")} {
		font-size: 17px;
		width: 75%;
	}
`;

const SubTitleLink = styled.a`
	color: ${colors.primary.orange01};
	text-transform: uppercase;
`;

export const ProgramPageStyled = {
	ContainerSubTitleText,
	SubTitleLink,
	SubTitleText,
};
