import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "design-system/theme";
import { TermAndConditionStyled } from "../styled";

const TermAndConditionAccordionProgram = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.neutral.gray03,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel1"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Programa
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					1.1 O Programa de relacionamento AGREGA (“Programa”) é idealizado,
					desenvolvido, promovido e administrado pela BASF S.A. (“BASF”),
					divisão de Proteção de Cultivos Brasil. Ele tem como objetivo
					proporcionar ao agricultor, cliente do Canal de Distribuição e/ou
					Cooperativa BASF participante, a possibilidade de acumular pontos
					através da compra de defensivos agrícolas e/ou sementes elegíveis
					("Produtos BASF"), podendo trocar tais pontos por prêmios (produtos ou
					serviços), na forma prevista no presente regulamento.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionRescue = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.primary.orange02,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel2"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Resgate
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					7.1. Os pontos acumulados poderão ser trocados por produtos ou
					serviços, mediante resgate, de acordo com a quantidade de pontos
					exigida. Os produtos e serviços estarão disponíveis através do
					Catálogo de Prêmios vigente na época da solicitação de resgate dos
					pontos.
					<br />
					<br />
					7.2. O resgate pelo Participante pressupõe: (i) saldo de pontos
					disponível; (ii) observância das regras e limites definidos neste
					Regulamento; e (iii) observância das regras e limites definidos em
					regulamentos específicos para cada um dos serviços e/ou produtos a
					serem resgatados.
					<br />
					<br />
					7.3. A troca de pontos por prêmios está sujeita à disponibilidade dos
					serviços e/ou produtos na região do domicílio cadastrado pelo
					Participante no Site Conecta. A disponibilidade dos prêmios e seus
					respectivos modelos são variáveis, de acordo com a disponibilidade do
					fornecedor e de mercado. A troca solicitada pelo Participante somente
					será considerada completa após o Participante finalizar a transação
					pelo Site Conecta e receber e-mail com tal confirmação.
					<br />
					<br />
					7.4. O prêmio é pessoal e intransferível. Sob nenhuma hipótese haverá
					a substituição do produto/serviço por dinheiro ou quaisquer outros
					bens diferentes dos constantes no Catálogo de Prêmios vigente no site
					do Programa AGREGA, exceto se o modelo do produto/serviço estiver fora
					de linha no momento da entrega, podendo ser substituído por um modelo
					similar. Caso não seja possível realizar a substituição do produto ou
					serviço em falta, o pedido será cancelado e os pontos serão devolvidos
					ao Participante.
					<br />
					<br />
					7.5. As especificações, a qualidade, a garantia e assistência técnica
					dos produtos/serviços trocados pelos Participantes, são de inteira e
					exclusiva responsabilidade dos respectivos fabricantes e/ou
					prestadores de serviços, isentando a BASF de qualquer reclamação dos
					Participantes nesse sentido. O Participante deverá guardar os
					certificados de garantia e a(s) nota(s) fiscal(is) do(s) produto(s)
					e/ou serviço(s) para troca em caso de defeitos, mau funcionamento, má
					realização ou para eventual assistência técnica no prazo de garantia,
					e deverá requerer diretamente aos fabricantes e/ou prestadores de
					serviços a reposição, troca, e/ou assistência técnica dos prêmios
					adquiridos.
					<br />
					<br />
					7.6. Os fabricantes e/ou prestadores de serviços dos produtos/serviços
					serão responsáveis pelas trocas, estoques, qualidade e reposição dos
					produtos, observadas as formas e prazos previstos na legislação
					vigente. Desta forma, a entrega dos produtos ou a prestação dos
					serviços selecionados pelo Participante, uma vez confirmada nos termos
					do item 7.3, será de exclusiva responsabilidade do respectivo parceiro
					fornecedor do produto ou serviço no Site Conecta, isentando a BASF de
					qualquer responsabilidade em razão de quaisquer atrasos ou falhas na
					entrega dos produtos ou na prestação dos serviços.
					<br />
					<br />
					7.7. O Participante poderá realizar resgates de produtos e serviços,
					desde que cumpra as premissas pontuadas no item 7.2.
					<br />
					<br />
					7.8. O Participante poderá solicitar o cancelamento de um resgate
					desde que o faça no prazo de 7 (sete) dias contados de sua
					confirmação, através do Site Conecta. No entanto, caso este prazo não
					seja respeitado, o produto/serviço será entregue ou haverá o
					cancelamento da entrega, sem a reintegração da pontuação utilizada
					pelo Participante.
					<br /> <br />
					7.9. Havendo suspeita de inconsistências e/ou fraudes, tanto no
					cadastro do Participante quanto no ato de resgate dos
					produtos/serviços, a BASF poderá suspender ou cancelar o resgate de
					pontos pelos Participantes.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionRulesAndScore = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.neutral.gray03,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel3"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Regras de Pontuação
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					5.1. A lista com os Produtos elegíveis para conversão de pontos será
					disponibilizada no Site AGREGA e será atualizada de tempos em tempos.
					<br />
					<br />
					5.2. Os produtos e/ou serviços disponibilizados para resgate, somente
					poderão ser trocados através da plataforma conecta pontos, e os pontos
					serão contabilizados através da conversão automática do valor dos
					Produtos em pontos, mediante tratamento, por empresa terceira
					contratada pela BASF, dos dados de faturamento enviados pelo
					distribuidor autorizado.
					<br />
					<br />
					5.1.1. A BASF poderá modificar a pontuação dos Produtos ou excluir
					produtos elegíveis, a qualquer momento, mediante simples comunicação
					disponibilizada no Site AGREGA.
					<br />
					<br />
					5.1.2 A aquisição de produtos BASF de distribuidores não
					participantes, conforme definido no item 2.1 e 2.2 deste regulamento,
					não possibilitará o acúmulo de pontos.
					<br />
					<br />
					<b>5.2. Sistemática de Pontuação</b>
					<br />
					<br />
					5.2.1. Para fins de pontuação, serão consideradas apenas as notas
					fiscais que contenham Produtos BASF elegíveis ao Programa, e emitidas
					com algum dos CFOPs (Código Fiscal de Operações e Prestações) listados
					abaixo:
					<br />
					<br />
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							5102
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5104
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5106
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5112
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5114
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5115
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							5117
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5119
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5120
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5160
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5403
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6102
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							6104
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6106
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6108
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6110
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6112
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6114
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							6115
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6117
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6119
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6120
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6403
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6160
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<br />
					<br />
					5.2.2. Todas as compras de Produtos BASF elegíveis junto aos Canais de
					Distribuição e/ou Cooperativas BASF realizadas pelos Participantes
					serão convertidas em pontos: a cada R$ 1,00 (um real) gasto para
					Produtos na categoria Lançamento, serão concedidos 2 (pontos), e a
					cada R$ 1,00 (um real) gasto para os demais produtos elegíveis, será
					concedido 1 (um) ponto, conforme disposto no item 5.1, através da
					lista de Produtos disponibilizada no Site AGREGA.
					<br />
					<br />
					5.2.3 O preço dos Produtos que será utilizado para cálculo da
					pontuação será o valor final do produto contido na nota fiscal.
					<br />
					<br />
					5.2.4 A BASF poderá alterar a proporção de compras e pontos a qualquer
					momento, com comunicação através do Site AGREGA, com antecedência
					mínima de 60 (sessenta) dias.
					<br />
					<br />
					5.2.5 Qualquer discordância na quantidade de pontos será resolvida
					através da apresentação, pelo Participante, das notas fiscais de
					compra dos Produtos, em até 210 (duzentos e dez) dias após a data da
					emissão da nota fiscal.
					<br />
					<br />
					5.2.6 O Participante começará a pontuar no programa somente após o
					cadastro no Site Conecta e no conecta pontos e aceite aos termos do
					Programa AGREGA, e não terá direito à pontuação de compras
					retroativas; a partir do cadastro, o Participante passará a pontuar em
					todas as compras elegíveis de Produtos participantes realizadas em
					qualquer Canal de Distribuição e/ou Cooperativas BASF participante do
					Programa. Para tanto, o Participante deverá conferir a lista de
					parceiros participantes no Site AGREGA.
					<br />
					<br />
					5.2.7 O extrato de pontos do presente Programa poderá ser acompanhado
					pelo Participante através do Site AGREGA e pelo conecta pontos.
					<br />
					<br />
					5.2.8 Os pontos serão creditados na conta do Participante após 35
					(trinta e cinco) dias da data de emissão da nota fiscal das compras
					efetuadas pelo Participante, no Canal de Distribuição e/ou
					Cooperativas BASF participantes. A pontuação será enviada para a BASF
					de forma automática pelo sistema do Canal de Distribuição e/ou
					Cooperativas participantes, conforme descrito no item 5.2.
					<br />
					<br />
					5.2.9 Caso o Participante realize a devolução de algum dos Produtos
					considerados elegíveis, os respectivos pontos serão cancelados e
					subtraídos da pontuação total do Participante. Caso o Participante já
					tenha utilizado a pontuação, esse saldo de pontos constará como
					negativo. Abaixo lista das CFOPs de devolução:
					<br />
					<br />
					<div style={{ display: "block" }}>
						<div style={{ border: "1px solid white", textAlign: "center" }}>
							Códigos CFOPs de Devolução
						</div>
						<div style={{ display: "flex" }}>
							<TermAndConditionStyled.BoxNumbers>
								6115
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6117
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6119
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6120
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6403
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6160
							</TermAndConditionStyled.BoxNumbers>
						</div>
					</div>
					<br />
					<br />
					5.2.10 Caso um determinado Canal de Distribuição BASF deixe de ser
					parceiro da BASF no presente Programa, o mesmo deverá comunicar os
					Participantes de que as compras de Produtos elegíveis através daquele
					Canal de Distribuição não serão mais pontuadas. A BASF atualizará a
					lista de distribuidores parceiros no Site AGREGA, contudo não possuirá
					qualquer gestão sobre a comunicação realizada pelo Canal.
					<br />
					<br />
					5.2.11 No caso de campanhas sazonais promovidas pela BASF, serão
					aplicadas as regras de acúmulo de pontos, de acordo com seus
					respectivos regulamentos.
					<br />
					<br />
					<b>Expiração dos Pontos:</b>
					<br />
					<br />
					9.1 Cada ponto disponível para resgate possui o prazo de validade de
					24 (vinte e quatro) meses, contados a partir da disponibilização do
					crédito dos pontos, cuja informação estará disponível nos Sites AGREGA
					e Conecta, em área de acesso restrito.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionExpirationScore = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.primary.orange02,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel4"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Expiração dos Pontos
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					9.1 Cada ponto disponível para resgate possui o prazo de validade de
					24 (vinte e quatro) meses, contados a partir da disponibilização do
					crédito dos pontos, cuja informação estará disponível nos Sites AGREGA
					e Conecta, em área de acesso restrito.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionMigrationScore = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.neutral.gray03,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel5"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Migração de Pontos do Antigo Programa
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
					malesuada lacus ex, sit amet blandit leo lobortis eget.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordion = () => {
	return (
		<>
			{TermAndConditionAccordionProgram()}
			{TermAndConditionAccordionRescue()}
			{TermAndConditionAccordionRulesAndScore()}
			{TermAndConditionAccordionExpirationScore()}
			{TermAndConditionAccordionMigrationScore()}
		</>
	);
};

export default TermAndConditionAccordion;
