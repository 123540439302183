
/* eslint-disable @typescript-eslint/member-ordering */
import { IGetDocumentType } from "../../data/protocols/services/documentType/get-documentType";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";
import { StorageHelper } from "../../utils/functions/storage/storage";

export class HttpGetDocumentType implements IGetDocumentType{
  constructor(
    private readonly httpClient: HttpClient<
      IGetDocumentType.Params,
      IGetDocumentType.HttpResponse
    >,
    private readonly storageHelper: StorageHelper
  ) { }

  private headerSubscriptionKey = {
    Authorization: `Bearer ${this.storageHelper.get('token')}`,
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
    ["X-Session-Id"]: this.storageHelper.get("sessionKey"),
  };

  async getDocumentLink(documentNumber?: string, documentType?: number): Promise<IGetDocumentType.Result> {
    const result = await this.httpClient.request({
      headers: this.headerSubscriptionKey,
      method: HttpMethods.GET,
       url: `${process.env.REACT_APP_BASE_URL_API}participant/participants/document-associated/${documentNumber}/${documentType}`,
    });
    return {      
      response: result?.response.data,
      statusCode: result?.statusCode,
      success: result?.success,
    } as IGetDocumentType.Result;
  }
}
