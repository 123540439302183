import MosaicImage1Row1 from "./mosaic-image1-row1.png"
import MosaicImage2Row1 from "./mosaic-image2-row1.png"
import MosaicImage1Row2 from "./mosaic-image1-row2.png"
import MosaicImage2Row2 from "./mosaic-image2-row2.png"
import MosaicSmallImageRow1 from "./mosaic-small-image-row1.png"
import MosaicSmallImageRow2 from "./mosaic-small-image-row2.png"
import MosaicSmallImage3Row1 from "./mosaic-small-image3-row1.png"

export const MosaicImages = {
    MosaicImage1Row1,
    MosaicImage1Row2,
    MosaicImage2Row1,
    MosaicImage2Row2,
    MosaicSmallImage3Row1,
    MosaicSmallImageRow1,
    MosaicSmallImageRow2
    
}