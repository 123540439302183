import { breakpoints } from './../../design-system/theme/breakpoints';
import { colors } from "design-system/theme";
import styled from "styled-components";

const Container = styled.main`
    display: block;
    height: 100%;
    margin: 65px auto;
    max-height: 100%;
    max-width: 100%;
    padding: 0 2%;
    width: 85%;
`;

const ProductContainer = styled.section`
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
`;

const ProductRow = styled.div`
    align-items: center;
    align-self: center;
    background-color: ${colors.generics.white};
    border-bottom: 1px solid ${colors.neutral.gray01};
    color: ${colors.generics.darkBlue};
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 50%;

    &:nth-child(odd) {
        background-color: ${colors.generics.grayLight};
    }

    &:hover {
        opacity: 0.9;
    }

    ${breakpoints.down("sm")} {
        width: 90%;
    }
`;

const ContainerSubTitleText = styled.section`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    max-width: 1000px;
    max-height: 100%;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 50px 75px;   

    ${breakpoints.up('lg')} {
        padding: 0 0 50px 175px;   
    }
    ${breakpoints.down('md')} {
        padding: 0 20px 20px;
    }
`;


const SubTitleText = styled.p`
    color: ${colors.neutral.gray03};
    display: block;
    font-size: 16px;
    line-height: 1.3;
    width: 100%;

    ${breakpoints.down("sm")} {
        font-size: 17px;
        width: 75%;
        margin-top: -25px;
    }
`;

export const ProductPageStyled = {
    Container,
    ContainerSubTitleText,
    ProductContainer,
    ProductRow,
    SubTitleText
}
