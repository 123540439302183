import styled from "styled-components"
import { colors } from "../../../design-system/theme"
import { TitleIcons } from '../../../design-system/icons'
import { breakpoints } from "../../../design-system/theme/breakpoints"


const Container = styled.div`
    padding: 80px 150px 0 ;
    ${breakpoints.down('md')} {
        padding: 0 20px;
      
    }
`

const Row = styled.div`
    padding: 50px  0;

    ${breakpoints.down('sm')} {
        padding: 50px 0;
    }
`

const Col12 = styled.div`
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    
   
`

const TitleContent = styled.h2`
    background: url(${TitleIcons.SquareIcon});
    background-repeat: no-repeat;
    color: ${colors.primary.orange02};
    font-size: 30px;
    font-weight: 700;
    line-height: 0.8;
    min-height: 73.5px;
    padding-left: 75px;

    ${breakpoints.down("sm")} {
        margin-left: unset;
    }
   
`

const IconSquare = styled.img`
    margin-right: 20px;
`

export const TitlePageStyled = {
    Col12,
    Container,
    IconSquare,
    Row,
    TitleContent
}