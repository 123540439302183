import { FC } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationsProps } from "../../../design-system/domain/components";

const NotificationError: FC<NotificationsProps> = ({ customId, message, position }) => {
  const toastId = customId ?  customId :  "custom-id";

  toast.error(message, {
    position: position ? position : toast.POSITION.BOTTOM_CENTER,
    toastId: toastId,
  });

  return <ToastContainer autoClose={5000} />;
};

export default NotificationError;
