/* eslint-disable no-debugger */
/* eslint-disable sort-keys */
import { useSelector } from "react-redux";
import { Http } from "api";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useTitle } from "hooks";
import { Styled } from "./style";
import { TitlePage, TableList, ContainerPage } from "design-system/components";
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpLegacyExtract } from "services/extract/legacy-extract";
import { ILoadLegacyExtract } from "data/protocols/services/extract/legacy-extract";
import { DateHelper } from "utils/functions/date/date";
import { useNavigate } from "react-router-dom";
import { SelectSearchItem } from "design-system/domain";
import { HttpGetDocumentType } from "services/documentType/DocumentType"
import { IGetDocumentType } from "data/protocols/services/documentType/get-documentType";
import ModalLogout from "modal/modal-logout";
 
 
const LegacyExtractPage = () => {
  const [msgError, setMsgError] = useState('');
  const [document, setDocument] = useState<string>("")
  const [cnpjs, setCnpjs] = useState<IGetDocumentType.IDocumentType>()
  const [showModalLogout, setShowModalLogout] = useState<boolean>(false)
 
  const http = new Http();
  const httpGetDocumentType = new HttpGetDocumentType(http, new StorageHelper());
 
 
  const [datesFilter, setDatesFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [extractData, setExtractData] =
    useState<Omit<ILoadLegacyExtract.RowsAndTotal, 'statusCode'>>({
      message: "",
      rows: [],
      total: 0,
    });
  const { user } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  useEffect(() => {
 
    getDocumentTypeParticipant()
    if (!user.is_logged) {
      navigate("/programa");
    }
  }, []);
  useTitle(`Extrato legado`);
 
  const httpLegacyExtract = new HttpLegacyExtract(
    new Http(),
    new StorageHelper()
  );
  const dateHelper = new DateHelper();
 
 
  const getDocumentTypeParticipant = async () => {
    const result = await httpGetDocumentType.getDocumentLink(user.document_number as string, 2);
 
    if (result?.statusCode === 401) {
      setShowModalLogout(true)
    }
    if (result?.success) {
      result.response.rows.unshift(user.document_number as string)
      setCnpjs(result.response);
    }
 
  }
 
  const fetchExtract = async () => {
    setMsgError('');
    try {
      if (datesFilter.endDate == '' || datesFilter.startDate == '') {
        setMsgError('Selecione a data desejada!')
        return
      }
      const result = await httpLegacyExtract.getExtract({
        data: {
          documentNumber: document as string,
          endDate: dateHelper.convertAmericanFormatToBrazilFormat(
            datesFilter.endDate
          ),
          startDate: dateHelper.convertAmericanFormatToBrazilFormat(
            datesFilter.startDate
          ),
        },
      })
      if (result.statusCode === 401) {
        setShowModalLogout(true)
        return;
      }
      if (result.total === 0) {
        setMsgError(result.message)
      } else {
        setMsgError('');
        setExtractData({
          message: result.message,
          rows: result.rows,
          total: result.total,
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        setShowModalLogout(true)
      } else {
        setMsgError(error.response.data.message)
      }
    }
 
 
  };
 
  useEffect(() => {
    if (!user.is_logged) {
      navigate("/");
    }
  }, [user]);

  return (
    <>
      <ModalLogout isOpen={showModalLogout} />
      <ContainerPage>
        <TitlePage
          title={`Extrato anterior ao dia ${process.env.REACT_APP_LEGACYDATE}`}
        />
 
        <Styled.ContainerSubTitleText>
          <Styled.SubTitleText>
            Consulte aqui o histórico de transações realizadas antes do dia{" "}
            {process.env.REACT_APP_LEGACYDATE}. <br /><br />
            A pesquisa está limitada ao período máximo de 90 dias, a partir da data inicial.<br /><br />
            Selecione o CPF ou CNPJ. Caso seu CNPJ não esteja na lista fale com a central de atendimento.
          </Styled.SubTitleText>
          <Styled.SubTitleText>
 
          </Styled.SubTitleText>
        </Styled.ContainerSubTitleText>
 
        <Styled.RowInputs>
          <Styled.SelectStyled
            placeholder="Selecione o documento"
            value={document || null}
            onChange={event => setDocument(event as string)}
            options={
              cnpjs?.rows?.map((c, key: number) => ({
                key,
                label: c,
                value: c,
              })) as SelectSearchItem[]
            }
          />
 
          <Styled.InputDates
            value={datesFilter.startDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDatesFilter({
                ...datesFilter,
                startDate: e.target.value,
              })
            }
            type="date"
          />
          <Styled.InputDates
            value={datesFilter.endDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDatesFilter({
                ...datesFilter,
                endDate: e.target.value,
              })
            }
            type="date"
          />
          <Styled.SearchExtractButton onClick={() => fetchExtract()}>
            Pesquisar
          </Styled.SearchExtractButton>
 
        </Styled.RowInputs>
        <Styled.SubTitleTextError>
          {msgError != '' ? (<p>{msgError}</p>) : null}
        </Styled.SubTitleTextError>
        <TableList
          data={{
            columnAlign: {
              'Pontos': 'right',
              'Tipo de transação': 'center'
            },
            columnTitles: [
              "Data de transação",
              "Tipo de transação",
              "Pontos",
            ],
            rows: extractData.rows != null ? extractData.rows.map(
              (extract: ILoadLegacyExtract.ExtractRow) => ({
                ["Data de transação"]: extract.transactionDate.toString(),
                ["Tipo de transação"]: extract.transactionType.toString(),
                ["Pontos"]: extract.points.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
              })
            ) : [],
            totalItems: extractData.total,
          }}
        />
      </ContainerPage>
    </>
  );
};
 
export default LegacyExtractPage;