import styled from "styled-components";
import { breakpoints, colors } from "../../../../design-system/theme";
import { InputStyledProps } from "../../../../design-system/domain/components";

const InputCustomized = styled.input<InputStyledProps>`
  background-clip: padding-box;
  background-color: ${(props: InputStyledProps) =>
    props?.backgroundColor ? props?.backgroundColor : "none"};
  border: ${(props: InputStyledProps) =>
    props?.border ? props?.border : `1px solid ${colors.neutral.gray04}`};
  border-radius: ${(props: InputStyledProps) =>
    props?.borderRadius ? props?.borderRadius : "0.25rem"};
  color: ${(props: InputStyledProps) =>
    props?.color ? props?.color : `${colors.neutral.gray03}`};
  display: block;
  font-size: ${(props: InputStyledProps) =>
    props?.fontSize ? props?.fontSize : "1rem"};
  font-weight: 400;
  height: ${(props: InputStyledProps) =>
    props?.height ? props?.height : "calc(1.5em + 0.75rem + 2px)"};
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: ${(props: InputStyledProps) =>
    props?.width ? props?.width : "300px"};

  ${breakpoints.down("sm")} {
    max-width: 100%;
    width: ${(props: InputStyledProps) =>
    props?.width ? props?.width : "96%"};
  }

  &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    padding-left: 5px;
  }
  :-ms-input-placeholder {
    padding-left: 5px;
  }
`;

export const InputStyled = {
  InputCustomized
};
