import { FC } from "react";
import { LayoutContainerStyled } from './styled'
import { LayoutProps } from "../../../design-system/domain";

const Layout: FC<LayoutProps> = ({ children }) => (
  <LayoutContainerStyled.Layout>
    {children}
  </LayoutContainerStyled.Layout>
)

export default Layout;