/* eslint-disable @typescript-eslint/member-ordering */
import { StorageHelper } from "../../utils/functions/storage/storage";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";
import { IInvoiceRegister } from "../../data/protocols/services/invoice/register";

export class HttpInvoiceRegister implements IInvoiceRegister {
  constructor(
    private readonly httpClient: HttpClient<
      IInvoiceRegister.Params,
      IInvoiceRegister.HttpResponse
    >,
    private readonly storageHelper: StorageHelper
  ) { }

  private headerSubscriptionKey = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${this.storageHelper.get("token")}`,
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
    ["X-Session-Id"]: this.storageHelper.get("sessionKey"),
    
  };

  async create(params?: IInvoiceRegister.Params): Promise<{
    response: IInvoiceRegister.HttpResponse;
    statusCode: number
    success: boolean;
  }> {
    const result = await this.httpClient.request({
      data: params?.data,
      headers: this.headerSubscriptionKey,
      method: HttpMethods.POST,
       url: `${process.env.REACT_APP_BASE_URL_API}participant/invoice/create`,
    });
    return result as { response: IInvoiceRegister.HttpResponse; statusCode: number; success: boolean };
  }
}
