import { breakpoints, colors } from "design-system/theme";
import styled from "styled-components";
import { TermAndConditionStyledProps } from "design-system/domain";
import { Button } from "design-system/components";


const Container = styled.main`
    display: block;
    height: 100%;
    margin: 55px auto;
    max-height: 100%;
    max-width: 100%;
    padding: 0 2%;
    width: 100%;
    box-sizing: border-box;
    ${breakpoints.up('lg')} {
        padding: 0 150px;
    }
`;

const ContainerSubTitleText = styled.section`
align-items: center;
display: flex;
flex-direction: row;
height: 100%;
justify-content: center;
max-width: 1000px;
max-height: 100%;
margin: 0;
width: 100%;
box-sizing: border-box;
padding: 0 0 50px 75px;   

${breakpoints.up('lg')} {
    padding: 0 0 50px 175px;   
}
${breakpoints.down('md')} {
    padding: 0 20px 20px;
}
`;

const SubTitleText = styled.p`
    color: ${colors.neutral.gray03};
    display: block;
    font-size: 16px;
    line-height: 1.3;
    width: 100%;

    ${breakpoints.down("sm")} {
        font-size: 17px;
        width: 75%;
        margin-top: -25px;
    }
`;

const TermAndConditionBlock = styled.section<TermAndConditionStyledProps>`
    background-color: ${(props: TermAndConditionStyledProps) => props?.backgroundColor ? props?.backgroundColor : colors.primary.orange02};   
    color: ${(props: TermAndConditionStyledProps) => props?.color ? props?.color : colors.primary.orange02};
    margin: 0 10px;
    margin-top: ${(props: TermAndConditionStyledProps) => props?.marginTop ? props?.marginTop : "0"};
    padding: 2% 0;
`;

const TermAndConditionBlockTitle = styled.h2<TermAndConditionStyledProps>`
    color: ${(props: TermAndConditionStyledProps) => props?.color ? props?.color : colors.primary.orange02};
    margin-top: 10px;
    padding: 3% 5%;
    text-align: left;
`;

const TermAndConditionBlockText = styled.p<TermAndConditionStyledProps>`
    color: ${(props: TermAndConditionStyledProps) => props?.color ? props?.color : colors.primary.orange02};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    padding: 0 5%;
    text-align: left;
`;

const TermAndConditionBlockTextCustomized = styled(TermAndConditionBlockText)`
    text-align:  right;
    padding: 10px;
    ${breakpoints.down("sm")} {
        display: inline-block;
        padding: 0;
    }
`;

const TermAndConditionContainerButton = styled.section`
    border-top: 1px solid ${colors.primary.orange02};
    display: flex;
    justify-content: end;
    margin: 25px auto 0;
    max-width: 100%;
    padding-top: 20px;
    width: 100%;
    
    ${breakpoints.down("sm")} {
        justify-content: center;
        flex-direction: column;
    }
`;

const TermAndConditionButtonCustomized = styled(Button)`
    padding: 5px;
    margin-left: 20px;

    ${breakpoints.down("sm")} {
        font-size: 13px;
        margin-left: 30px;
        padding: 5px;
    }
`;

const TermAndConditionButtonCustomized2 = styled(Button)`
    padding: 5px 30px;
    margin-left: 20px;

    ${breakpoints.down("sm")} {
        font-size: 13px;
        margin-left: 15px;
        padding: 5px 10px;
    }
`;

const TermDownloadButton = styled(Button)`
    padding: 5px;
    margin: 0 20px;
    ${breakpoints.down("sm")} {
        font-size: 13px;
        width: 100%;
        margin: 0 0 20px;
    }
`;
const TermAcceptButton = styled(Button)`
    padding: 5px;

    ${breakpoints.down("sm")} {
        font-size: 13px;
        width: 100%;
    }
`;

const ContainerButtons = styled.section`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
`;
const Paginator = styled.p`
    display: block;
    text-align: center;
    margin-bottom: 10px ;

`
const CloseButtom = styled.a`
    position: absolute ;
    right: 20px;
    top: 20px;
    font-size: 30px;
    cursor: pointer;
`

const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
`

const CustomConfirmButton = styled(Button)`
    background-color: ${colors.primary.gradient};
`

const CustomCancelButton = styled(Button)`
    background-color: ${colors.generics.grayLight};
    color: ${colors.generics.darkBlue};
    &:hover{
         color: ${colors.generics.white};
    }
`

const BoxNumbers = styled.div`
    border: 1px solid ${colors.generics.white};
    text-align: center;
    width: 120px;
`
export const TermAndConditionStyled = {
    BoxNumbers,
    CloseButtom,
    Container,
    ContainerButtons,
    ContainerSubTitleText,
    CustomCancelButton,
    CustomConfirmButton,
    ModalFooter,
    Paginator,
    SubTitleText,
    TermAcceptButton,
    TermAndConditionBlock,
    TermAndConditionBlockText,
    TermAndConditionBlockTextCustomized,
    TermAndConditionBlockTitle,
    TermAndConditionButtonCustomized,
    TermAndConditionButtonCustomized2,
    TermAndConditionContainerButton,
    TermDownloadButton,
}