/* eslint-disable no-debugger */
/* eslint-disable sort-keys */
/* eslint-disable no-mixed-spaces-and-tabs */
import { useSelector } from "react-redux";
import { Http } from "api";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useTitle } from "hooks";
import { Styled } from "./style";
import { TitlePage, TableList, ContainerPage } from "design-system/components";
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpLegacyExtract } from "services/extract/extract-nf";
import { ILoadLegacyExtract } from "data/protocols/services/extract/legacy-extract";
import { DateHelper } from "utils/functions/date/date";
import { useNavigate } from "react-router-dom";
import ModalLogout from "modal/modal-logout";

const initialStateExtractDataFactory = (): Omit<ILoadLegacyExtract.RowsAndTotal, 'statusCode'> => ({
	message: "",
	rows: [],
	total: 0,
})

const NFPage = () => {
	const [msgError, setMsgError] = useState("");
	const [datesFilter, setDatesFilter] = useState({
		startDate: "",
		endDate: "",
	});
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false)
	const [extractData, setExtractData] =
		useState<Omit<ILoadLegacyExtract.RowsAndTotal, 'statusCode'>>(initialStateExtractDataFactory());
	const { user } = useSelector((state: RootState) => state);
	const navigate = useNavigate();
	useEffect(() => {
		if (!user.is_logged) {
			navigate("/programa");
		}
	}, []);
	useTitle(`Notas fiscais`);

	const httpLegacyExtract = new HttpLegacyExtract(
		new Http(),
		new StorageHelper()
	);
	const dateHelper = new DateHelper();

	const fetchExtract = async () => {
		setMsgError("");
		setExtractData(initialStateExtractDataFactory())
		try {
			if (datesFilter.endDate == "" || datesFilter.startDate == "") {
				setMsgError("Selecione a data desejada!");
				return;
			}
			const result = await httpLegacyExtract.getExtract({
				data: {
					documentNumber: user.document_number as string,
					endDate: dateHelper.convertAmericanFormatToBrazilFormatWithBars(
						datesFilter.endDate
					),
					startDate: dateHelper.convertAmericanFormatToBrazilFormatWithBars(
						datesFilter.startDate
					),
				},
			});

			if (result.statusCode === 401) {
				setShowModalLogout(true)
				return;
			}

			if (result.total === 0) {
				setMsgError("Não foram encontrados resultados para a busca.");
				setExtractData(initialStateExtractDataFactory())
			} else {
				setMsgError("");
				setExtractData({
					message: result.message,
					rows: result.rows,
					total: result.total,
				});
			}
		} catch (error: any) {
			if (error?.response?.status === 401) {
				setShowModalLogout(true)
				return;
			}
			if (error.data === null) {
				setMsgError(error.data.message);
			}
		}
	};

	useEffect(() => {
		if (!user.is_logged) {
			navigate("/");
		}
	}, [user]);

	return (
		<>
			<ModalLogout isOpen={showModalLogout} />
			<ContainerPage>
				<TitlePage title={`Extrato de Notas Fiscais`} />
				<Styled.ContainerSubTitleText>
					<Styled.SubTitleText>
						Consulte aqui o histórico de Notas Fiscais
					</Styled.SubTitleText>
				</Styled.ContainerSubTitleText>

				<Styled.RowInputs>
					<Styled.InputDates
						value={datesFilter.startDate}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setDatesFilter({
								...datesFilter,
								startDate: e.target.value,
							})
						}
						type="date"
					/>

					<Styled.InputDates
						value={datesFilter.endDate}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setDatesFilter({
								...datesFilter,
								endDate: e.target.value,
							})
						}
						type="date"
					/>
					<Styled.SearchExtractButton onClick={() => fetchExtract()}>
						Pesquisar
					</Styled.SearchExtractButton>
				</Styled.RowInputs>
				<Styled.SubTitleTextError>
					{msgError != "" ? <p>{msgError}</p> : null}
				</Styled.SubTitleTextError>
				<TableList
					data={{
						columnTitles: [
							"Data de emissão da NF",
							"Documento",
							"CNPJ da revenda",
							"Número da NF",
							"Código da NF",
							"Valor",
							"Data cadastramento da NF",
							"Status da NF",
						],
						rows:
							extractData.rows != null
								? extractData.rows.map((extract: any) => ({
									["Data de emissão da NF"]: extract.emissionDate.toString() == '01/01/0001' ? '-' :  extract.emissionDate.toString(),
									["Documento"] : extract.documentParticipant,
									["CNPJ da revenda"]: extract.documentDealer,
									["Número da NF"]: extract.invoiceNumber,
									["Código da NF"]: extract.barCode,
									["Valor"]: extract.amount,
									["Data cadastramento da NF"]: extract.createdAt.toString(),
									["Status da NF"]: extract.status,
								}))
								: [],
						totalItems: extractData.total,
					}}
				/>
			</ContainerPage>
		</>
	);
};

export default NFPage;
