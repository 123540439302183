export class StorageHelper {
  clearAll(): void {
    const lastAccessDate = localStorage.getItem('lastAccessDate');
    localStorage.clear();
    lastAccessDate !== null && localStorage.setItem('lastAccessDate', lastAccessDate);
  }

  get<T>(key: string): T {
    const result = localStorage.getItem(key);
    return result as T;
  }

  set<T extends string>(key: string, value: T): void {
    localStorage.setItem(key, value);
  }
}
