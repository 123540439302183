import { ILoadProvidersList } from "../../data/protocols/services/provider/list";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";

export class HttpLoadProvidersList implements ILoadProvidersList {
  constructor(
    private readonly httpClient: HttpClient<
      ILoadProvidersList.Params,
      ILoadProvidersList.HttpResponse
    >
  ) { }

  private headerSubscriptionKey = {
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
  };
  async load(
    params?: ILoadProvidersList.Params
  ): Promise<{ response: ILoadProvidersList.HttpResponse; statusCode: number; success: boolean }> {
    let filters = "";
    for (const param in params) {
      if (
        params[param as keyof ILoadProvidersList.Params] ||
        params[param as keyof ILoadProvidersList.Params] === 0
      ) {
        if (param === "pageIndex" || param === "pageSize") {
          filters += `paginate.${param}=${params[param as keyof ILoadProvidersList.Params]
            }&`;
        } else {
          filters += `${param}=${params[param as keyof ILoadProvidersList.Params]}&`;
        }
      }
    }

    const result = await this.httpClient.request({
      headers: this.headerSubscriptionKey,
      method: HttpMethods.GET,
      url: `${process.env.REACT_APP_BASE_URL_API}hotsite/distribuitor/getall?${filters}Status=1`,
    });

    return result as { response: ILoadProvidersList.HttpResponse; statusCode: number; success: boolean };
  }
}
