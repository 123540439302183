/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpClient } from "../data/protocols/http/http-client";


export class Http<HttpParams = any, HttpResponse = any>
  implements HttpClient<HttpParams, HttpResponse>
{
  private readonly GENERIC_ERROR_MESSAGE: string = 'Generic error';
  async request(params?: HttpParams): Promise<{ response: HttpResponse; statusCode: number; success: boolean } | void> {
    try {
      const httpResult: AxiosResponse<HttpResponse> = await axios.request(
        params as AxiosRequestConfig
      );

      return {
        response: httpResult.data,
        statusCode: httpResult.status,
        success: true
      }
    }
    catch (error: any) {
      return {
        response: error?.response?.data ? error?.response?.data : this.GENERIC_ERROR_MESSAGE,
        statusCode: error?.response?.status,
        success: false
      }
    }
  }
}
