// import { colors } from "design-system/theme";
import styled from "styled-components";

const Layout = styled.section`
    padding: 155px 0 0 0 ;
    min-height: 100vh;
    position: relative;
    box-sizing: border-box;
`

export const LayoutContainerStyled = {
    Layout
}