import styled, { CSSProp } from "styled-components";
import { InputBase } from "@mui/material";
import { colors } from "../../../../design-system/theme";
import { AlignOption } from "../../../../design-system/domain";

const defineContainerDisplay = (
  displayOption: AlignOption
): {
  containerStyle: CSSProp,
  labelStyle: CSSProp,
} => {
  switch (displayOption?.labelAlignOptions) {
    case "left":
      return {
        containerStyle: {
          width: "fit-content",
        },
        labelStyle: {
            "margin-top": "-1px"
        },
      };
    case "top":
    default:
      return {
        containerStyle: {
          width: "min-content",
        },
        labelStyle: {
          "margin":  "0 3px 0 10px"
        },
      };
    case 'bottom': {
        return {
            containerStyle: {
                "display": "flex",
                "flex-direction": "column-reverse"
            },
            labelStyle: {
                'margin-top': '5px'
            }
        }
    }
  }
};
const InputDateCustomized = styled(InputBase)`
  .MuiInputBase-input {
    border: 1px solid ${colors.neutral.gray09};
    border-radius: 4px;
    color: ${colors.neutral.gray08};
    font-size: 16px;
    height: 30px;
    margin-top: 10px;
    padding: 5px 0 5px 15px;
    width: 185px;

    &:focus {
      border-color: ${colors.primary.orange02};
      box-shadow: 0.5px 0 4px 0.5px ${colors.primary.orange04};
    }
  }
`;

const ContainerInput = styled.div<AlignOption>`
    ${(props: AlignOption) => defineContainerDisplay(props).containerStyle}
`;

const InputLabel = styled.label`
  ${(props: AlignOption) => defineContainerDisplay(props).labelStyle};
  font-size: 14px;
`;


export const InpuDateStyled = {
  ContainerInput,
  InputDateCustomized,
  InputLabel,
};
