import styled from "styled-components";
import { FileTextOutlined, QuestionCircleOutlined, QuestionOutlined } from '@ant-design/icons'
import { colors } from "design-system/theme/colors";
import { breakpoints } from "design-system/theme";


const BannerHome = styled.img`
    display: none;

    ${breakpoints.up('md')} {
        display: inherit;
        margin-left: 31vh;
        height: 65vh;
        margin-top:27px
    }


`

const FirstSectionContainer = styled.div`
    margin-top: 25px;
    padding: 0 15px;

    ${breakpoints.up('md')} {
        display: flex;
        width: 55%;
        padding: 0px 20vh;
    }
`

const FirstSectionTitle = styled.h2`
    color:  ${colors.primary.orange02};
    font-size: 30px;
    letter-spacing: -2px;

    .company-name {
        background: linear-gradient(to right, ${colors.primary.red} 0%, ${colors.primary.orange02} 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    ${breakpoints.up('md')} {
        font-size: 52px;
    }
    ${breakpoints.up('lg')} {
        font-size: 64px;
    }
`

const Subtitle = styled.p`
    color: ${colors.neutral.gray10};
    line-height: 20px;
    margin-top: 15px;

    ${breakpoints.up('md')} {
        width: 45vh;
    }
`

const SecondSectionContainer = styled.div`
    background: ${colors.neutral.gray14};
    height: auto;
    margin-top: 20px;
    padding: 0 15px;

    ${breakpoints.up('md')} {
        padding: 5vh 20vh;
    }
`

const SecondSectionTitle = styled.p`
    color: ${colors.primary.orange02};
    font-size: 18px;
    font-weight: bold;

    ${breakpoints.up('md')} {
        font-size: 36px;
    }
`

const SecondSectionProgram = styled.div`
    display: grid;
    justify-content: center;
    width: 100%;

    .logo-img {
        height: 85px;
        margin: auto;
    }

    .box-program-description {
        display: grid;
        justify-content: center;
        margin-top: 45px;
        
        ${breakpoints.up('md')} {
            display: flex;
            margin-right: 130px;
        }
    }

    .program-description {
        color: ${colors.neutral.gray10};
        font-size: 14px;
        line-height: 21px;
        margin-top: 20px;
        padding: 5px 10px;

        ${breakpoints.up('md')} {
            font-size: 16px;
            line-height: 30px;        
            margin-left: 15px;
            margin-top: 0px;
        }
    }

    ${breakpoints.up('md')} {
        display: flex;
    }
`

const ThirdSectionContainer = styled.div`
    margin-top: 25px;
    padding: 0 15px;

    .box-common-we {
        ${breakpoints.up('md')} {
            padding: 10vh 15vh 0 0;
        }
    }

    ${breakpoints.up('md')} {
        margin-top: 0;
        padding: 0 0 0 20vh;
        display: flex;
    }
`

const ThirdSectionTitle = styled.p`
    color: ${colors.primary.orange02};
    font-size: 21px;
    font-weight: bolder;

    ${breakpoints.up('md')} {
        font-size: 36px;
    }
`

const ThirdSectionFirstParagraph = styled.p`
    color: ${colors.neutral.gray10};
    margin-bottom: 35px;
    margin-top: 20px;
    padding: 0 15px;

    ${breakpoints.up('md')} {
        font-size: 18px;
        line-height: 30px;
        padding: 0;
    }
`

const ThirdSectionSecondParagraph = styled(ThirdSectionFirstParagraph)`
`

const ThirdSectionThirdParagraph = styled(ThirdSectionFirstParagraph)`
    color: ${colors.primary.orange02};
    font-weight: bold;
`

const FourthSection = styled.div`
    background: ${colors.primary.orange02};
    height: auto;
    padding: 10px 15px;

    ${breakpoints.up('md')} {
        display: flex;
        flex-direction: row-reverse;
        padding: 0 35vh 0 16vh;
    }
`

const BoxImg = styled.div`
    display: flex;
    justify-content: center;

    img {
        height: 45vh;
        
        ${breakpoints.up('md')} {
            height: 65vh;
            margin-top:25px
        }
    }
`

const HowIAccessBox = styled.div`
    color: ${colors.generics.white};
    margin-top: 40px;
    padding: 0 10px 20px;

    .title {
        font-size: 21px;
        font-weight: bolder;
        margin-left: 35px;
        margin-top: -25px;

        ${breakpoints.up('md')} {
            font-size: 30px;
        }
    }

    ul {
        list-style-type: square;
        padding: 0 45px;
    }

    li {
        line-height: 25px;
        margin-top: 30px;

        ${breakpoints.up('md')} {
            font-size: 18px;
        }
    }

    .row-access-conecta {
        margin-top: 45px;
        text-align: center;
    
        button {
            background: ${colors.primary.red};
            border: none;
            border-radius: 25px;
            color: ${colors.generics.white};
            cursor: pointer;
            font-weight: 600;
            height: 40px;
            width: 75%;

            ${breakpoints.up('md')} {
                font-size: 18px;
            }
        }
    }
`



const FifthSessionContainer = styled.div`
    margin-top: 40px;
    padding: 0 15px;

    .logo-version {
        height: 30px;
        padding-bottom: 10px;

        ${breakpoints.up('md')} {
            height: 35px;        
            margin-right: 5px;
        }
    }

    .logo-img-fifth-session{ 
        height: 13px;

        ${breakpoints.up('md')} {
            height: 28px;
        }
    }
`

const FifthSessionTitle = styled.p`
    color:  ${colors.primary.orange02};
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -2px;

    ${breakpoints.up('md')} {
        font-size: 38px; 
        margin-left: 16vh;
    }
`

const RowComparisons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    width: 100%;
`

const RowLogos = styled.div`
    display: flex;
    width: 100%;
    margin-bottom:20px;
    ${breakpoints.up('md')} {
        padding: 0 16.57vh;        
    }  

    #logo-ag {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50%;
        padding-botton:20px;

      
    }

    #logo-pontos{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 50%;
        padding-botton:20px

       
    }

    ${breakpoints.up('md')} {
        text-align: center;
    }
`

const RowFeatures = styled.div`
    display: flex;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;

    ${breakpoints.up('md')} {
        margin-top: 1px;
        padding: 0 16.57vh;        
    }


    .features-ag {
        background: ${colors.neutral.gray15};
        color: ${colors.neutral.gray10};        
        padding: 12px 5px;
        width: 45%;

        ${breakpoints.up('md')} {
            font-size: 16px;
            height: 5vh;
            padding-top: 27px;
        }
    }

    .features-score {
        background: ${colors.neutral.outlineCards};
        color: ${colors.primary.orange02};        
        padding: 12px 5px;
        width: 50%;

        ${breakpoints.up('md')} {
            font-size: 16px;            
            height: 5vh;
            padding-top: 27px;
        }
    }
`

const SixthSessionContainer = styled.div`
    background: ${colors.primary.orange02};
    margin-top: 25px;
    padding: 25px 15px;

    ${breakpoints.up('md')} {
        height: 15vh;
        padding: 5vh 0 15vh 18.57vh;
    }

    #sixthSessionTitle{
        color: ${colors.generics.white};        
        font-size: 20px;
        font-weight: 700;

        ${breakpoints.up('md')} {
            font-size: 32px;
        }
    }

    #contact-us {
        color: ${colors.generics.white};        
        font-size: 16px;
        line-height: 16px;
        margin-top: 22px;
        padding: 0 4px;

        ${breakpoints.up('md')} {
            font-size: 18px;
        }
    }
`

const SeventhContainer = styled.div`
    background: ${colors.neutral.gray15};
    height: auto;
    padding: 55px 8vh;

    #title {
        color: ${colors.primary.orange02};
        font-size: 20px;
        font-weight: 700;

        ${breakpoints.up('md')}{
            font-size: 35px;
        }
    }

    ${breakpoints.up('md')} {
        padding: 12vh 20vh;
    }
`

const SeventhCardCommonQuestionsRow = styled.div`
    display: grid;
    margin-top: 20px;

    ${breakpoints.up('md')} {
        display: flex;
        justify-content: space-between;
    }
`

const SeventhCardCommonQuestions = styled.div`
    background: ${colors.generics.white};
    border-radius: 5px;
    cursor: pointer;
    height: auto;
    margin-top: 30px;
    overflow: auto;
    padding: 10px 15px;
    width: 85%;

    ${breakpoints.up('md')} {
        margin-right: 15px;
        width: 25%;
    }

    #question-title {
        color: ${colors.neutral.gray16};
        font-size: 15px;

        ${breakpoints.up('md')} {
            font-size: 18px;
        }
    }

    #answer {
        color: ${colors.neutral.gray10};
        font-size: 12px;
        margin-top: 40px;

        ${breakpoints.up('md')} {
            font-size: 16px;
            margin-top: 60px;        
        }
    }
`

const SeventhSessionRowOrangeBoxes = styled.div`
    display: grid;
    margin-top: 35px;
    padding: 10px 0;

    ${breakpoints.up('md')} {
        display: flex;
        justify-content: space-between;
    }

`

const SeventhSessionOrangeBoxes = styled.div`
    background: ${colors.primary.orange02};
    border-radius: 12px;
    color: ${colors.generics.white};
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 200;
    margin-top: 20px;
    padding: 10px 15px;
    width: 80%;

    ${breakpoints.up('md')} {
    
        font-size: 16px;        
        height: auto;
        width: 25%;
    }
    ${breakpoints.up('lg')} {
        font-size: 16px;        
        height: auto;
        margin-right: 15px;
        width: 25%;
    }

`

const IconQuestionOrangeBox = styled(QuestionCircleOutlined)`
    color: ${colors.generics.white};
    font-size: 20px;
    margin-right: 15px;
    margin-top: 5px;

    ${breakpoints.up('md')} {
        margin-top: 10px;   
    }
`

const IconPaperOrangeBox = styled(FileTextOutlined)`
    color: ${colors.generics.white};
    font-size: 20px;
    margin-right: 15px;
    margin-top: 5px;

    ${breakpoints.up('md')} {
        margin-top: 10px;   
    }
`

const IconQuestionOutlinedOrangeBox = styled(QuestionOutlined)`
    color: ${colors.generics.white};
    font-size: 20px;
    margin-right: 15px;
    margin-top: 5px;

    ${breakpoints.up('md')} {
        margin-top: 10px;    
    }

    p {
        ${breakpoints.up('md')} {
            margin-top: 15px;    
        }
    }
`

const WomanAgroBusinessImg = styled.img`
    display: none;

    ${breakpoints.up('md')} {
        display: inherit;
        height: 60vh;
    }
`

const BoxTitle = styled.div`
    ${breakpoints.up('md')} {
        width: 45vh;
    }
`

const RowSquare = styled.div`
    display: none;

    ${breakpoints.up('md')}{
        display: flex;
        justify-content: end;
        margin-right: 70px;

        img {
            transform: scaleX(-1);
        }
    }
`

const ModalContent = styled.div`
    margin-top: 10px;

    .modal-content-text {
        color: ${colors.neutral.gray10};
        font-size: 14px;
    }

    .talk-us-attendance {
        color: ${colors.primary.red};
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
    }
`

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;

    button {
        border-radius: 25px;
        cursor: pointer;
        height: 35px;
        margin-top: 25px;
        width: 45%;
    }

    .see-now {
        background: ${colors.primary.red};
        border: none;
        color: ${colors.generics.white};
    }

    .see-later {
        background: transparent;
        border: 1px solid ${colors.primary.red};
        color: ${colors.primary.red};
    }
`



export const HomePageStyled = {
    BannerHome,
    BoxImg,
    BoxTitle,
    FifthSessionContainer,
    FifthSessionTitle,
    FirstSectionContainer,
    FirstSectionTitle,
    FourthSection,
    HowIAccessBox,
    IconPaperOrangeBox,
    IconQuestionOrangeBox,
    IconQuestionOutlinedOrangeBox,
    ModalContent,
    ModalFooter,
    RowComparisons,
    RowFeatures,
    RowLogos,
    RowSquare,
    SecondSectionContainer,
    SecondSectionProgram,
    SecondSectionTitle,
    SeventhCardCommonQuestions,
    SeventhCardCommonQuestionsRow,
    SeventhContainer,
    SeventhSessionOrangeBoxes,
    SeventhSessionRowOrangeBoxes,
    SixthSessionContainer,
    Subtitle,
    ThirdSectionContainer,
    ThirdSectionFirstParagraph,
    ThirdSectionSecondParagraph,
    ThirdSectionThirdParagraph,
    ThirdSectionTitle,
    WomanAgroBusinessImg
}