import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "store/domain";

const INITIAL_STATE: UserState = {
	agrega_opt_in_conecta_ag: null,
	agrega_opt_in_opt_in_terms_and_privacy: null,
	agrega_opt_in_score: null,
	aud: null,
	document_number: null,
	email: null,
	exp: null,
	iat: null,
	is_logged: false,
	iss: null,
	modal_check_your_data_already_show_this_session: null,
	name: null,
	nbf: null,
	optin_comunication: null,
	points: null,
	terrena: null,
	token: null,
};

const sliceUser = createSlice({
	initialState: INITIAL_STATE,
	name: "user",
	reducers: {
		populateUser(_state, { payload }: PayloadAction<UserState>) {
			return {
				...payload,
			};
		},
		setModalCheckYourDataShowThisSessionAsTrue(state) {
			return {
				...state,
				modal_check_your_data_already_show_this_session: true,
			};
		},
		setOptComunication(state, { payload }: any) {
			return {
				...state,
				optin_comunication: payload,
			};
		},
		setOptiInsAsTrue(state, { payload }: PayloadAction<boolean>) {
			return {
				...state,
				agrega_opt_in_conecta_ag: true,
				agrega_opt_in_opt_in_terms_and_privacy: true,
				agrega_opt_in_score: true,
				optin_comunication: payload
			};
		},
		setScore(state, { payload }: PayloadAction<number>) {
			return {
				...state,
				points: payload,
			};
		},
	},
});

export default sliceUser.reducer;

export const {
	populateUser,
	setModalCheckYourDataShowThisSessionAsTrue,
	setScore,
	setOptComunication,
	setOptiInsAsTrue,
} = sliceUser.actions;

export const useUser = (state: { [key: string]: unknown }) => {
	return state.user as UserState;
};
