/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { CustomInputDate } from './style';
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.css";

registerLocale("pt", pt)

const theme = createTheme(
    ptBR,
);

const CustomDate = (props: any) => {
    const onChange = (date: any, name: any) => {
        props.onChange(date, name)
    }
    const { ...rest } = props;

  return (
        <ThemeProvider theme={theme}>
            <CustomInputDate.Container>
                {rest.labelText &&
                    <CustomInputDate.Label htmlFor={rest.id}>
                        {rest.labelText}
                        {rest.required && <span className='required'>*</span>}
                    </CustomInputDate.Label>
                }
                <DatePicker
                    {...rest}
                    id={rest.id}
                    locale='pt'
                    dateFormat="PP"
                    onChange={(date: any) => onChange(date, props.name)}
                    customInput={
                        <CustomInputDate.InputDate
                            type='text'
                            readOnly={true}
                        />
                    }
                />

            </CustomInputDate.Container>
        </ThemeProvider >
    );
};

export default CustomDate;