import logoConectaAgHome from "./logoConectaAg.png";
import logoConectaPontos from "./logoConectaPontos.png";
import computerAccess from "./computerAccess.png";
import squareWhite from "./squaresWhite.png";
import logoConectaPontosLabel from "./logoConectaPontosLabel.png";
import logoConectaAGLabel from "./logoAgregaLabel.png";
import bannerHome from "./bannerHome.png";
import agribusinessWoman from "./agribusinessWoman.png";
import logoAgrega from "./logoAgrega.png";
import logoConectAGModal from "./logoConectaAGModal.png";
import labelNew from "./labelNew.svg";
import labelOld from "./labelOld.svg";
import Maintenance from "./maintenance.png";

export const HomeImages = {
	Maintenance,
	agribusinessWoman,
	bannerHome,
	computerAccess,
	labelNew,
	labelOld,
	logoAgrega,
	logoConectAGModal,
	logoConectaAGLabel,
	logoConectaAgHome,
	logoConectaPontos,
	logoConectaPontosLabel,
	squareWhite,
};
