import styled from "styled-components";
import { breakpoints, colors } from "../../../../design-system/theme";
import { InputStyledProps } from "../../../../design-system/domain/components";

const SelectCustomized = styled.select<InputStyledProps>`
  background-clip: padding-box;
  background-color: ${(props: InputStyledProps) =>
    props?.backgroundColor ? props?.backgroundColor : "none"};
  border: ${(props: InputStyledProps) =>
    props?.border ? props?.border : `1px solid ${colors.neutral.gray04}`};
  border-radius: ${(props: InputStyledProps) =>
    props?.borderRadius ? props?.borderRadius : " 0.25rem"};
  color: ${(props: InputStyledProps) =>
    props?.color ? props?.color : `${colors.neutral.gray03}`};
  display: block;
  font-size: ${(props: InputStyledProps) =>
    props?.fontSize ? props?.fontSize : "1rem"};
  font-weight: 400;
  height: ${(props: InputStyledProps) =>
    props?.height ? props?.height : "calc(1.5em + 1rem + 10px)"};
  margin-bottom: 25px;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: ${(props: InputStyledProps) =>
    props?.width ? props?.width : "200px"};

  ${breakpoints.down("sm")} {
    max-width: 100%;
    width: ${(props: InputStyledProps) =>
      props?.width ? props?.width : "100%"};
  }
`;

export const SelectStyled = {
  SelectCustomized,
};
