import { FC } from "react";
import { Modal } from "../../../design-system/components";
import { HomeImages } from "../../../design-system/images";

import { UserNotLoggedStyled } from "./styled";

export type ModalUserIsNotLoggedProps = {
	handleCloseModal: () => void;
	isOpen: boolean;
	queryParamValue: string;
};

const ModalUserIsNotLogged: FC<ModalUserIsNotLoggedProps> = ({
	handleCloseModal,
	isOpen,
	queryParamValue,
}) => (
	<Modal
		style={{
			padding: 0,
			zIndex: 10000000000
		}}
		header={
			<UserNotLoggedStyled.HeaderRow>
				<UserNotLoggedStyled.CloseButtom onClick={() => handleCloseModal()}>
					<UserNotLoggedStyled.CloseIconCustom />
				</UserNotLoggedStyled.CloseButtom>
			</UserNotLoggedStyled.HeaderRow>
		}
		content={
			<>
				<UserNotLoggedStyled.ModalBody>
					<UserNotLoggedStyled.Title>IMPORTANTE</UserNotLoggedStyled.Title>

					<UserNotLoggedStyled.BoxAgrega>
						<img
							className="logo"
							src={HomeImages.logoAgrega}
							alt="Logo do AGREGA"
						/>
						<br />
						<p className="description">
							Já é cadastrado no AGREGA? Então, em seu primeiro acesso será
							necessário redefinir sua senha, clicando na opção{" "}
							<a
								className="link-redirect"
								href={`${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/forgotpassword/`}
							>
								‘Esqueci minha senha’
							</a>
							. Após cadastrar a sua nova senha, realize seu login.
						</p>
					</UserNotLoggedStyled.BoxAgrega>

					<UserNotLoggedStyled.BoxAgrega>
						<img
							className="logo"
							src={HomeImages.logoConectAGModal}
							alt="Logo do conecta.ag"
						/>
						<br />
						<p className="description">
							Se você já é
							<b>
								{" "}
								cadastrado no conecta.ag{" "}
								<a
									className="link-redirect"
									href={`${process.env.REACT_APP_CONECTA_AG_LOGIN_URL}?redirect=${queryParamValue}`}
								>
									acesse a sua conta
								</a>
							</b>
							<br />
							<br />
							Ainda não é cadastrado? Clique abaixo e crie sua conta.
						</p>
					</UserNotLoggedStyled.BoxAgrega>

					<UserNotLoggedStyled.RowButton>
						<UserNotLoggedStyled.CustomConfirmButton
							buttonText="Crie uma conta"
							eventClick={() =>
								(window.location.href = `${process.env.REACT_APP_CONECTA_AG_LOGIN_URL}?redirect=${queryParamValue}`)
							}
						/>
					</UserNotLoggedStyled.RowButton>
				</UserNotLoggedStyled.ModalBody>
			</>
		}
		titleAlign={`center`}
		open={isOpen}
		size={`SMALL`}
	/>
);

export default ModalUserIsNotLogged;
