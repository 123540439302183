/* eslint-disable no-debugger */
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	ContainerPage,
	SelectSearch,
	TitlePage,
	Notifications,
	NotificationError,
} from "design-system/components/index";
import { ContainerFormStyled } from "./styled";
import { useTitle } from "hooks/index";
import { InputProps, SelectSearchItem } from "design-system/domain";
import { HttpGetAllProvidersList } from "services/provider/get-all";
import { HttpGetParticipant } from "services/participant/Participant";
import { Http } from "api";
import { IGetAllHeadquarters } from "data/protocols/services/provider/get-all";
import { IGetAllCultures } from "data/protocols/services/culture/get-all";
import { HttpGetAllCulturesList } from "services/culture/get-all";
import { StorageHelper } from "utils/functions/storage/storage";
import { StatusRequest } from "utils/consts/status-request";
import { RootState } from "store";
import { HttpRegistrationRedirect } from "services/account-user/redirect";
import { HttpRegistrationComplement } from "services/account-user/complement";
import { setOptiInsAsTrue } from "store/slices/user";
import { useNavigate } from "react-router-dom";
import ModalLogout from "modal/modal-logout";
import { toast } from "react-toastify";

export type FormCultures = {
	error: boolean;
	fields: {
		culture: { error: boolean; value: null | string | undefined };
		cultureId: { error: boolean; value: null | string | undefined };
		hectares: any;
	}[];
};

const participantInitialState = {
	activationDate: "",
	actuationArea: null,
	birthdate: "",
	businessBoard: "",
	businessManager: null,
	city: "",
	complementAddress: "",
	culturesHectares: [{ culture: "", cultureId: "", hectares: 0 }],
	desactivationDate: null,
	distribuidorId: "",
	documentNumber: "",
	gender: "",
	gtvName: "",
	houseNumber: "",
	mobileNumber: "",
	name: "",
	neighborhood: "",
	participantImportedId: "",
	participantStatus: "",
	phoneNumber: null,
	segmentation: "",
	state: "",
	streetAddress: "",
	topFarmer: false,
	vuCode: "",
	vuName: "",
	zipCode: "",
};
const RegisterPage: FC<InputProps> = () => {
	const _defaultCultures = [
		{
			culture: { error: false, value: "" },
			cultureId: { error: false, value: "" },
			hectares: { error: false, value: 0 },
		},
	];

	const [cultureForms, setCultureForm] = useState<FormCultures>({
		error: false,
		fields: _defaultCultures,
	});
	const { user } = useSelector((state: RootState) => state);
	const [providerId, setProviderId] = useState<string | null>(null);
	const [moreCulture, setMoreCulture] = useState<boolean>(true);
	const [disableButtonState, setDisableButtonState] = useState<boolean>(true);
	const [render, rerender] = useState(false);
	const [statusRequestApi, setStatusRequestApi] = useState<null | StatusRequest.ERROR>(null);
	const [customMessageError, setCustomMessageError] = useState("");
	const [headquarters, setHeadquarters] = useState<
		IGetAllHeadquarters.IGetAllHeadquartersList[] | null
	>(null);
	const [cultures, setCultures] = useState<
		IGetAllCultures.IGetAllCulturesList[] | null
	>(null);
	const [promotionCode, setPromotionCode] = useState<string | null>(null);
	const [distribuidorIdAux, setDistribuidorIdAux] = useState<string | null>(
		null
	);
	const [distribuidorIdError, setdistribuidorIdError] =
		useState<boolean>(false);
	const [statusRegisterComplement, setStatusRegisterComplement] =
		useState<StatusRequest | null>(null);
	const [optIns, setOptIns] = useState({
		agrega_opt_in_conecta_ag: user.agrega_opt_in_conecta_ag,
		agrega_opt_in_score: user.agrega_opt_in_score,
		agrega_opt_in_terms_and_privacy:
			user.agrega_opt_in_opt_in_terms_and_privacy,
	});
	const [isCheck, setIscheck] = useState<boolean>(false);
	const [opsComunication, setOpsComunication] = useState<any>("0");
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false)

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const http = new Http();
	const httpGetAllProviders = new HttpGetAllProvidersList(http);
	const httpGetParticipantAux = new HttpGetParticipant(
		new Http(),
		new StorageHelper()
	);

	const httpGetCultures = new HttpGetAllCulturesList(http, new StorageHelper());
	const httpRegistrationRedirect = new HttpRegistrationRedirect(
		http,
		new StorageHelper()
	);
	const httpRegistrationComplement = new HttpRegistrationComplement(
		http,
		new StorageHelper()
	);

	const optinIsTrue =
		user.agrega_opt_in_conecta_ag ||
		user.agrega_opt_in_opt_in_terms_and_privacy ||
		user.agrega_opt_in_score;

	const checkHandler = () => {
		setIscheck(!isCheck);
		setOpsComunication(isCheck ? "0" : "1");
	};

	const termLink =
		"https://apiagregabasf.caosdata.com.br/images/REGULAMENTO%20PROGRAMA%20AGREGA_AGRICULTOR_VF_Mar2022%20(1).pdf";

	const removeCulture = (e: SyntheticEvent, key: number) => {
		e.preventDefault();
		const inputsState = [...cultureForms.fields];

		inputsState.splice(key, 1);
		setCultureForm({
			...cultureForms,
			fields: inputsState,
		});
	};

	const addMoreCulture = (e: SyntheticEvent) => {
		e.preventDefault();
		setMoreCulture(true);
		setStatusRegisterComplement(null);
		if (moreCulture) {
			setCultureForm({
				...cultureForms,
				fields: [...cultureForms.fields, _defaultCultures[0]],
			});
		}
	};

	const fetchHeadQuartersList = async (): Promise<void> => {
		const result = await httpGetAllProviders.getAll();
		if (result?.statusCode === 401) {
			setShowModalLogout(true)
		} else {
			setHeadquarters(result.response);
		}
	};

	const fetchCulturesList = async (): Promise<void> => {
		const result = await httpGetCultures.getAll();
		if (result?.statusCode === 401) {
			setShowModalLogout(true)
		} else {
			setCultures(result.response);
		}
	};

	const [participant, setParticipant] = useState({
		activationDate: "",
		actuationArea: null,
		birthdate: "",
		businessBoard: "",
		businessManager: null,
		city: "",
		complementAddress: "",
		culturesHectares: [{ culture: "", cultureId: "", hectares: 0 }],
		desactivationDate: null,
		distribuidorId: "",
		documentNumber: "",
		gender: "",
		gtvName: "",
		houseNumber: "",
		mobileNumber: "",
		name: "",
		neighborhood: "",
		participantImportedId: "",
		participantStatus: "",
		phoneNumber: null,
		segmentation: "",
		state: "",
		streetAddress: "",
		topFarmer: false,
		vuCode: "",
		vuName: "",
		zipCode: "",
	});

	useEffect(() => {
		getParticipant();
	}, [user]);

	useEffect(() => {
		if (!user.is_logged) {
			navigate("/programa");
		}
	}, []);

	function filterCulturesHectares(
		culturesHectares: [{ culture: string; cultureId: string; hectares: number }]
	) {
		const culturesHectaresFiltered: any[] = [];
		culturesHectares.forEach((cultureFilter) => {
			culturesHectaresFiltered.push({
				culture: { error: false, value: cultureFilter?.culture },
				cultureId: { error: false, value: cultureFilter.cultureId },
				hectares: { error: false, value: cultureFilter.hectares },
			});
		});
		return culturesHectaresFiltered;
	}

	const getParticipant = async () => {
		const result = await httpGetParticipantAux.getAll(
			user.document_number as string
		);
		setParticipant({
			...participant,
			birthdate: result?.response?.birthdate,
			businessBoard: result?.response?.businessBoard,
			city: result?.response?.city,
			complementAddress: result?.response?.complementAddress,
			culturesHectares: result?.response?.culturesHectares,
			distribuidorId: result?.response?.distribuitorId,
			documentNumber: result?.response?.documentNumber,
			gender: result?.response?.gender,
			houseNumber: result?.response?.houseNumber,
			mobileNumber: result?.response?.mobileNumber,
			name: result?.response?.name,
			neighborhood: result?.response?.neighborhood,
			participantImportedId: result?.response?.participantImportedId,
			participantStatus: result?.response?.participantStatus,
		});

		setPromotionCode(result?.response?.promotionCode);
		setDistribuidorIdAux(result?.response?.distribuitorId);

		if (result?.response?.culturesHectares.length < 1) {
			setCultureForm({
				...cultureForms,
				fields: _defaultCultures,
			});
		} else {
			setCultureForm({
				...cultureForms,
				fields: filterCulturesHectares(result?.response?.culturesHectares),
			});
		}
	};

	useEffect(() => {
		if (headquarters == null) {
			fetchHeadQuartersList();
		}

		if (cultures == null) {
			fetchCulturesList();
		}
		if (distribuidorIdAux === null) {
			setDisableButtonState(true);
		}
	}, [headquarters, cultures]);

	useTitle("Cadastro");

	useEffect(() => {
		setDisableButtonState(true);

		function validOptin() {
			if (
				(!user.agrega_opt_in_conecta_ag && !optIns.agrega_opt_in_conecta_ag) ||
				(!user.agrega_opt_in_score && !optIns.agrega_opt_in_score) ||
				(!user.agrega_opt_in_opt_in_terms_and_privacy &&
					!optIns.agrega_opt_in_terms_and_privacy) ||
				distribuidorIdAux === "null" ||
				!distribuidorIdAux
			) {
				return;
			} else {
				setDisableButtonState(false);
			}
		}
		validOptin();
	}, [optIns, distribuidorIdAux]);

	const formIsValid = (): boolean => {
		const fieldsWithError = [];
		setdistribuidorIdError(false);

		if (!distribuidorIdAux) {
			fieldsWithError.push(distribuidorIdAux);
			setdistribuidorIdError(true);
		}

		cultureForms.fields.map((cultureField, key: number) => {
			const hectares = cultureField.hectares;
			const cultures = cultureField.culture;

			if (!cultures.value) cultures.error = true;

			if (!hectares.value || hectares.value === 0) hectares.error = true;

			const isInvalidForm = hectares.error || cultures.error;

			if (isInvalidForm) {
				setCultureForm({ ...cultureForms, error: true });
				setStatusRegisterComplement(StatusRequest.ERROR);

				fieldsWithError.push({ cultureField, key });
			}
		});

		return fieldsWithError.length > 0;
	};

	const fetchComplementAccount = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): Promise<void> => {
		try {
			e.preventDefault();

			setCultureForm({
				...cultureForms,
				error: false,
			});
			const hasError = formIsValid();

			if (!hasError) {
				const result = await httpRegistrationComplement.complement({
					data: {
						culturesHectares: cultureForms.fields.map((i) => ({
							culture: String(
								cultures?.find((c) =>
									c.id === i.culture.value
										? true
										: c.name.toUpperCase() === i.culture.value?.toUpperCase()
								)?.name
							),
							cultureId: String(
								cultures?.find((c) =>
									c.id === i.culture.value
										? true
										: c.name.toUpperCase() === i.culture.value?.toUpperCase()
								)?.id
							), //String(i.culture),
							hectares: Number(i.hectares.value),
						})),

						distribuitorId: String(distribuidorIdAux),
						optinAgrega: optIns.agrega_opt_in_conecta_ag,
						optinComunication: opsComunication,
						optinPoints: optIns.agrega_opt_in_score,
						optinTermsAndPrivacy: optIns.agrega_opt_in_terms_and_privacy,
						promotionCode: promotionCode,
					},
				});
				if (result.statusCode === 401) {
					setShowModalLogout(true);
					return;
				}
				if (result.statusCode === 400) {
					setStatusRequestApi(StatusRequest.ERROR);
					setCustomMessageError(String(result?.response?.message))
					return;
				}
				if (result.success) {
					setStatusRegisterComplement(StatusRequest.SUCCESS);
					dispatch(setOptiInsAsTrue(opsComunication === "1"));
					const log = await httpRegistrationRedirect.getUrlRedirect({
						data: {
							documentNumber: user.document_number as string,
							email: user.email as string,
						},
					});
					
					if (log.response.data) {
						setTimeout(() => {
							window.open(log.response.data, "_self");
						}, 3000);
					} else {
						toast.error('Falha ao redirecionar para o catálogo!', {
							autoClose: 5000,
							position: toast.POSITION.BOTTOM_CENTER,
						})
						return navigate('/', { replace: true })
					}

				} else {
					setStatusRegisterComplement(StatusRequest.ERROR);
				}
			}
		} catch (error: any) {
			if (error?.response?.status === 401) {
				setShowModalLogout(true)
			}
		}
	};

	function clearState() {
		setCultureForm({
			error: false,
			fields: [
				{
					culture: { error: false, value: "" },
					cultureId: { error: false, value: "" },
					hectares: { error: false, value: null },
				},
			],
		});
		setParticipant(participantInitialState);
		setProviderId(null);
		setPromotionCode(null);
		setDisableButtonState(true);
		setDistribuidorIdAux(null);
		setStatusRegisterComplement(null);
	}

	const setCultureAux = (e: any, index: any) => {
		const clone = cultureForms;
		clone.fields[index].culture.value = e as string;
		clone.fields[index].culture.error = false;
		clone.fields[index].cultureId.value = e as string;

		setCultureForm(clone);
		rerender(!render);
	};

	const handleHectares = (e: any, index: any) => {
		const isValidNumber = new RegExp("^[0-9]+$").test(e.target.value);
		const numberValid = isValidNumber ? e.target.value : 0;

		if (!isValidNumber) {
			e.target.value = "";
		}

		const clone = cultureForms;
		clone.fields[index].hectares.error = false;
		clone.fields[index].hectares.value = Number(numberValid);

		setCultureForm(clone);
		rerender(!render);
	};

	const handleCanal = (e: any) => {
		setDistribuidorIdAux(e as string);
		setProviderId(e as string);
	};

	const rowCultureComponent = (props: {
		culture: string | null | undefined;
		hectares: string | number | null | undefined;
		key: number;
	}): JSX.Element => {
		return (
			<>
				{headquarters?.length && cultures?.length ? (
					<>
						<ContainerFormStyled.NewContainerCultureRow>
							<ContainerFormStyled.BoxForms>
								<div>
									<ContainerFormStyled.FormLabelCustomized>
										Cultura<span>*</span>
									</ContainerFormStyled.FormLabelCustomized>
									<div>
										<SelectSearch
											placeholder="Selecione..."
											onChange={(e) => {
												setCultureAux(e, props.key);
											}}
											value={
												cultureForms?.fields[props.key].culture.value ||
												undefined
											}
											status={
												cultureForms?.fields[props.key].culture.error
													? "error"
													: ""
											}
											width={340}
											options={
												cultures?.map((c, key: number) => ({
													disabled: !!cultureForms.fields.find(
														(culturesState) =>
															culturesState.cultureId.value === c.id
													),
													key,
													label: c.name,
													value: c.id,
												})) as SelectSearchItem[]
											}
										/>
									</div>
								</div>
							</ContainerFormStyled.BoxForms>

							<ContainerFormStyled.BoxForms>
								<div>
									<ContainerFormStyled.FormLabelCustomized>
										Hectares<span>*</span>
									</ContainerFormStyled.FormLabelCustomized>
									<ContainerFormStyled.InputAcre
										value={
											cultureForms?.fields[props.key].hectares.value ||
											undefined
										}
										maxCharLenght={6}
										placeholder="000000"
										error={cultureForms?.fields[props.key].hectares.error}
										onChange={(e) => {
											handleHectares(e, props.key);
										}}
									/>
									{cultureForms?.fields[props.key].hectares.error && (
										<span className="asterisk">Campo Obrigatório*</span>
									)}
								</div>
							</ContainerFormStyled.BoxForms>
							{props.key ? (
								<ContainerFormStyled.ButtonRemoveCulture
									onClick={(e: SyntheticEvent) => removeCulture(e, props.key)}
								>
									Excluir
								</ContainerFormStyled.ButtonRemoveCulture>
							) : null}
						</ContainerFormStyled.NewContainerCultureRow>
					</>
				) : (
					<p>Carregando</p>
				)}
			</>
		);
	};

	return (
		<>
			<ModalLogout isOpen={showModalLogout} />

			{statusRegisterComplement === StatusRequest.SUCCESS && (
				<Notifications message="Cadastrado com sucesso" />
			)}
			{statusRegisterComplement === StatusRequest.ERROR && (
				<NotificationError message="*Preencha os campos vazios da lista de Culturas e Hectares" />
			)}
			{statusRequestApi === StatusRequest.ERROR && (
				<NotificationError position="bottom-right" message={customMessageError ? customMessageError : "Ocorreu um erro"} />
			)}
			
			<ContainerPage>
				<TitlePage title="Adesão ao Programa" />
				<ContainerFormStyled.ContainerSubTitleText>
					<ContainerFormStyled.SubTitle>
						Para participar do programa AGREGA você deve complementar o seu
						cadastro.
					</ContainerFormStyled.SubTitle>
				</ContainerFormStyled.ContainerSubTitleText>
				<ContainerFormStyled.ContainerForm>
					<ContainerFormStyled.FormCustomized>
						{!user.agrega_opt_in_conecta_ag ? (
							<>
								<ContainerFormStyled.FormTitle>
									Informações Adicionais
								</ContainerFormStyled.FormTitle>
								<ContainerFormStyled.ContainerCulture>
									{cultureForms.fields.map((_, key: number) =>
										rowCultureComponent({
											culture: _.culture.value,
											hectares: _.hectares.value,
											key,
										})
									)}
								</ContainerFormStyled.ContainerCulture>
								<ContainerFormStyled.ButtonAddCulture
									onClick={(e) => {
										addMoreCulture(e);
									}}
								>
									+ Adicionar Cultura
								</ContainerFormStyled.ButtonAddCulture>

								<div>
									<ContainerFormStyled.BoxForms>
										<ContainerFormStyled.FormLabelCustomized>
											Canal<span>*</span>
										</ContainerFormStyled.FormLabelCustomized>
										<SelectSearch
											placeholder="Selecione..."
											onChange={(e) => {
												handleCanal(e);
											}}
											dropdownMatchSelectWidth={340}
											width={340}
											status={distribuidorIdError ? "error" : ""}
											value={distribuidorIdAux || "Selecione..."}
											options={
												headquarters?.map((h, key: number) => ({
													key,
													label: h.nameGroup,
													value: h.id,
												})) as SelectSearchItem[]
											}
										/>
									</ContainerFormStyled.BoxForms>
								</div>

								<div>
									<ContainerFormStyled.BoxForms>
										<ContainerFormStyled.FormLabelCustomized>
											{" "}
											Indicação/Promoção (opcional)
										</ContainerFormStyled.FormLabelCustomized>
										<ContainerFormStyled.InputPromotionCode
											value={promotionCode}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												setPromotionCode(e.target.value)
											}
											placeholder={"Código Promocional"}
											maxCharLenght={25}
											required={false}
											type={"text"}
										/>
									</ContainerFormStyled.BoxForms>
								</div>
							</>
						) : (
							""
						)}
						{!user.agrega_opt_in_conecta_ag && (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={optIns.agrega_opt_in_conecta_ag}
									onChange={() =>
										setOptIns({
											...optIns,
											agrega_opt_in_conecta_ag:
												!optIns.agrega_opt_in_conecta_ag,
										})
									}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomized>
									<span>*</span> Eu li e concordo com o{" "}
									<ContainerFormStyled.Link
										target={"_blank"}
										href={process.env.REACT_APP_PROGRAM_REGULATION_CONTAINER}
									>
										Regulamento AGREGA
									</ContainerFormStyled.Link>
								</ContainerFormStyled.FormLabelCheckboxCustomized>
							</ContainerFormStyled.ContainerCheckbox>
						)}

						{!user.agrega_opt_in_score && (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={optIns.agrega_opt_in_score}
									onChange={() =>
										setOptIns({
											...optIns,
											agrega_opt_in_score: !optIns.agrega_opt_in_score,
										})
									}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomized>
									<span>*</span> Eu li e concordo com o{" "}
									<ContainerFormStyled.Link
										target={"_blank"}
										href={`${process.env.REACT_APP_CONECTA_AG_URL}/regulamento-fidelidade`}
									>
										Regulamento Conecta Pontos
									</ContainerFormStyled.Link>
								</ContainerFormStyled.FormLabelCheckboxCustomized>
							</ContainerFormStyled.ContainerCheckbox>
						)}

						{!user.agrega_opt_in_opt_in_terms_and_privacy && (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={optIns.agrega_opt_in_terms_and_privacy}
									onChange={() =>
										setOptIns({
											...optIns,
											agrega_opt_in_terms_and_privacy:
												!optIns.agrega_opt_in_terms_and_privacy,
										})
									}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomized>
									<span>*</span> Eu li e concordo com a{" "}
									<ContainerFormStyled.Link
										target={"_blank"}
										href={`${process.env.REACT_APP_CONECTA_AG_URL}/politica-de-privacidade`}
									>
										Política de Privacidade do programa
									</ContainerFormStyled.Link>
								</ContainerFormStyled.FormLabelCheckboxCustomized>
							</ContainerFormStyled.ContainerCheckbox>
						)}

						{user.optin_comunication === "null" ? (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={opsComunication}
									checked={isCheck}
									onChange={checkHandler}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomized>
									Aceito receber E-mails e/ou SMSs promocionais com ofertas e
									informações do Agrega
								</ContainerFormStyled.FormLabelCheckboxCustomized>
							</ContainerFormStyled.ContainerCheckbox>
						) : (
							""
						)}

						<ContainerFormStyled.ButtonCustomized
							eventClick={(
								e: React.MouseEvent<HTMLButtonElement, MouseEvent>
							) => fetchComplementAccount(e)}
							padding={"0.400rem 1.5rem"}
							buttonText="Salvar"
							disabled={disableButtonState}
						/>
						{!optinIsTrue ? (
							<ContainerFormStyled.FormRequiredFieldText>
								*Campos Obrigatórios
							</ContainerFormStyled.FormRequiredFieldText>
						) : (
							""
						)}

						{cultureForms.error && (
							<ContainerFormStyled.FormRequiredFieldText>
								*Preencha os campos vazios da lista de Culturas e Hectares
							</ContainerFormStyled.FormRequiredFieldText>
						)}
					</ContainerFormStyled.FormCustomized>
				</ContainerFormStyled.ContainerForm>					
			</ContainerPage>
		</>
	);
};

export default RegisterPage;
