/* eslint-disable @typescript-eslint/member-ordering */
import { env } from "../../config/env";
import { IJWTDecode } from "../../data/protocols/utils/jwt-decode";
import { ICheckToken } from "../../data/protocols/services/auth/check-token";
import { HttpClient, HttpMethods } from "../../data/protocols/http/http-client";

export class HttpAuthCheckToken implements ICheckToken {
  constructor(
    private readonly httpClient: HttpClient<
      ICheckToken.HttpParams,
      ICheckToken.HttpResponse
    >,
    private readonly jwtDecoder: IJWTDecode
  ) {}

  async check(
    params: ICheckToken.Params
  ): Promise<{ userInfo: IJWTDecode.Result; success: boolean }> {
    const result = await this.httpClient.request({
      headers: {
        ["Ocp-Apim-Subscription-Key"]: env.SUBSCRIPTION_KEY,
        accessToken: params.accessToken,
      },
      method: HttpMethods.POST,
      url: `${process.env.REACT_APP_BASE_URL_API}participant/auth/token`,
    });

    const userInfo = this.jwtDecoder.decode(String(result?.response?.data?.accessToken));

    return {
      success: result?.success,
      userInfo: {
        ...userInfo,
        token: result?.response.data.accessToken,
      },
    } as { userInfo: IJWTDecode.Result; success: boolean};
  }
}
