/**
 * 
 * @param words String que sera capitalizada
 * @description Essa função recebe uma string, reparte ela em um array,
 * remove os items do array que estão ' ', capitaliza a primeira letra de cada
 * palavra e junta novamente
 * @returns String capitalizada
 */

export const capitalizeWords = (words: string): string => {
  return words
    .split(' ')
    .filter(word => word)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
};
