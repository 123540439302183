import { FC } from "react";
import { useTitle } from "hooks";
import { isMobile } from "react-device-detect";
import {
    ContainerPage,
    TitlePage,
    Mosaic,
} from "design-system/components";
import { ProgramPageStyled } from "./styled";

const ProgramPage: FC = () => {

    useTitle("Programa");

    return (
        <>
            <ContainerPage>
                <TitlePage title="Como funciona o programa?" />
                <ProgramPageStyled.ContainerSubTitleText>
                    <ProgramPageStyled.SubTitleText>
                        AGREGA é o programa de relacionamento da BASF que oferece, ao agricultor participante, a oportunidade de acumular pontos a cada compra de produtos BASF elegíveis ao programa. Estes pontos podem ser trocados por produtos e serviços que poderão contribuir para o seu negócio, sua família, sociedade e o meio ambiente.<br /><br />
                        Para acumular seus pontos, basta adquirir os produtos em um dos distribuidores participantes (consulte a lista <ProgramPageStyled.SubTitleLink href="/distribuidores">AQUI</ProgramPageStyled.SubTitleLink>), você ganhará 1 ponto para cada real gasto nos produtos elegíveis (consulte a lista de produtos <ProgramPageStyled.SubTitleLink href="/produtos">AQUI</ProgramPageStyled.SubTitleLink>).<br /><br />
                        Os pontos que você acumular terão validade de 24 meses, a partir da data da compra.
                    </ProgramPageStyled.SubTitleText>
                </ProgramPageStyled.ContainerSubTitleText>

                {!isMobile ?
                    (
                        <Mosaic id={"mosaicProgramPage"}
                            alt={"Mosaico do BASF com vários blocos de imagens e blocos apenas preenchidos com um fundo alaranjado."}
                        />

                    ) :

                    <div />
                }

            </ContainerPage>
        </>
    );
};

export default ProgramPage;
