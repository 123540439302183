import styled from "styled-components";
import { colors, breakpoints } from "design-system/theme";
import { Select, Input } from "antd";

const Container = styled.main`
	display: block;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	padding: 0 150px;
	width: 100%;
	box-sizing: border-box;

	${breakpoints.down("sm")} {
		padding: 0 20px;
	}
`;

const SubTitleText = styled.p`
	color: ${colors.neutral.gray03};
	display: block;
	font-size: 16px;
	line-height: 1.3;
	width: 100%;

	${breakpoints.down("sm")} {
		font-size: 17px;
	}
`;

const SubTitleTextError = styled.div`
	color: red;
	background-color: ${colors.generics.grayLight};
	font-size: 16px;
	width: 100%;
	text-align: center;
	padding: 20px;
	${breakpoints.down("sm")} {
		font-size: 17px;
	}
`;
const ExtractPage = styled.div`
	background-color: ${colors.generics.grayLight};
`;

const RuleDescription = styled.p`
	color: ${colors.primary.red};
	margin-top: -30px;
	padding-bottom: 50px;
`;

const RowInputs = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 auto;
	padding: 50px 150px;
	background-color: ${colors.generics.grayLight};

	${breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: center;
		margin: 0;
		padding: 50px 10px;
	}
`;

const ContainerSubTitleText = styled.section`
	align-items: center;
	height: 100%;
	justify-content: center;
	max-width: 1000px;
	max-height: 100%;
	margin: 0;
	padding: 0 0 50px 175px;
	width: 100%;
	box-sizing: border-box;

	${breakpoints.down(600)} {
		padding: 0 0 20px 75px;
	}
`;

const SearchExtractButton = styled.button`
	background-color: ${colors.primary.orange01};
	border: 1px solid transparent;
	border-radius: 0.25rem;
	color: ${colors.neutral.backgroundTwo};
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	font-size: 1rem;
	height: 39px;
	line-height: 1.5;
	padding: 6px;
	text-align: center;
	transition: 0.3s ease-in;
	vertical-align: middle;

	&:hover {
		background-color: ${colors.primary.orange03};
	}

	${breakpoints.down("sm")} {
		font-size: 20px;
		margin: 15px 0;
		max-width: 100%;
		text-align: center;
		width: 100%;
	}
`;

const InputDates = styled(Input)`
	font-family: "Poppins";
	height: 38px;
	margin: 0 15px;
	text-align: center;
	width: 335px;
`;

const SelectStyled = styled(Select)`
	width: 45vh;
	margin: 5px 15px;
	font-family: "Poppins";
	text-align: center;
`;

export const Styled = {
	Container,
	ContainerSubTitleText,
	ExtractPage,
	InputDates,
	RowInputs,
	RuleDescription,
	SearchExtractButton,
	SelectStyled,
	SubTitleText,
	SubTitleTextError,
};
