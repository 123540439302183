import React from "react";

export type TableListProps = {
  backgroundColor?: string;
  border?: string;
  color?: string;
  data?: TableData;
  error?: TableError;
  fetching?: TableFetching
  paginationActions?: PageActions
};

export type TableError = {
  customErrorMessage?: string | React.ReactNode;
  hasError?: boolean;
};

export type TableFetching = {
  cutomFetchingMessage?: string | React.ReactNode;
  fetching?: boolean;
};

export type TableData = {
  columnAlign?: { [key: string]: 'right' | 'center' | 'left' }
  columnTitles?: string[];
  rows?: { [key: string]: string }[];
  totalItems?: number
};

export type TablePaginationActionsProps = {
  className?: string
  count: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  page: number
  rowsPerPage: number
  showFirstButton: boolean
  showLastButton: boolean
}

export type PageActions = {
  firstPageAction?: () => void;
  lastPageAction?: () => void;
  nextPageAction?: () => void;
  prevPageAction?: () => void;
};


export enum EventsTable {
  NEXT_PAGE = "NEXT_PAGE",
  PREV_PAGE = "PREV_PAGE",
  FIRST_PAGE = "FIRST_PAGE",
  LAST_PAGE = "LAST_PAGE",
}
