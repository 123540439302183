import styled from "styled-components";
import { Button } from "../../../design-system/components";
import { colors } from "../../../design-system/theme/colors";

const CloseButtom = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  display: block;
  margin-top: 20px;
`;

const CustomConfirmButton = styled(Button)`
  background-color: ${colors.primary.red};
`;

const HeaderRow = styled.div`
  height: 28px;
  margin-bottom: 15px;
  width: 100%;
`;


const RowButton = styled.div`
  margin-top: 35px;
  text-align: center;
`
export const RedirectComplementAccountStyled = {
  CloseButtom,
  CustomConfirmButton,
  HeaderRow,
  ModalBody,
  RowButton
};
