import { FC } from "react"
import { BlockedPageStyled } from './styled'
import { ContainerPage, TitlePage } from "design-system/components";

const BlockedPage: FC = () => (
    <ContainerPage>
        <TitlePage title="Ooops!" />
        <BlockedPageStyled.RowTextContent>
            <p>
                Lamentamos o inconveniente, mas devido à migração do nosso site alguns cadastros precisarão
                ter a documentação renovada. Por gentileza, entre em contato com a nossa Central de Relacionamento.
            </p>
        </BlockedPageStyled.RowTextContent>

        <BlockedPageStyled.RowButton>
            <BlockedPageStyled.ButtomAccessConecta
                buttonText="Entre em contato"
                eventClick={() =>
                    (window.location.href = `${process.env.REACT_APP_CONECTA_AG_URL}/contato`)
                }
            />
        </BlockedPageStyled.RowButton>

    </ContainerPage>
)
export default BlockedPage