import { FC, Fragment, useEffect, useState } from "react";
import { Http } from "api";
import { useTitle } from "hooks";
import { StatusRequest } from "utils";
import { ProviderPageStyled } from "./styled";
import { MProviderList } from "domain/models/provider/list";
import { HttpLoadProvidersList } from "services/provider/load-providers-list";
import {
  SearchForm,
  ContainerPage,
  TitlePage,
  TableList,
} from "design-system/components";
import ModalLogout from "modal/modal-logout";

type PaginationProps = {
  pageIndex: number;
  pageSize: number;
  totalItems: number;
};

const ProviderPage: FC = () => {
  const [emptyDataMessage, setEmptyDataMessage] = useState<string | null>(null)
  const [paginationProps, setPaginationProps] = useState<PaginationProps>({
    pageIndex: 0,
    pageSize: 10,
    totalItems: 0,
  });
  const [customParam, setCustomParam] = useState<{
    [key: string]: string | number;
  } | null>(null);
  const [providers, setProviders] = useState<MProviderList[] | null>(null);
  const [statusRequest, setStatusRequest] = useState<null | string>(null);
  const [showModalLogout, setShowModalLogout] = useState<boolean>(false)

  useTitle("Lista de distribuidores");

  const http = new Http();
  const httpLoadProvidersList = new HttpLoadProvidersList(http);

  const fetchProviders = async (operation?: 'NEXT' | 'PREV' | 'START' | 'LAST'): Promise<void> => {
    try {
      setStatusRequest(StatusRequest.FETCHING);
      setEmptyDataMessage(null)
      let httpParams: { [key: string]: string | number } = {
        pageIndex: operation === 'LAST' ? Math.floor(paginationProps.totalItems / paginationProps.pageSize)
          : operation === 'START' ? 0
            : operation === 'PREV' ? paginationProps.pageIndex - 1
              : paginationProps.pageIndex + 1,
        pageSize: paginationProps.pageSize,
      };

      if (customParam) {
        httpParams = {
          ...httpParams,
          ...customParam,
        };
      }

      httpLoadProvidersList.load(httpParams)
        .then((result) => {
          if (result?.statusCode === 401) {
            setShowModalLogout(true)
          } else {
            setPaginationProps({
              ...paginationProps,
              pageIndex: operation === 'START' ? 0 : operation === 'PREV' ? paginationProps.pageIndex - 1 : paginationProps.pageIndex + 1,
              totalItems: result.response.data.total,
            });
            setProviders(result.response.data.rows);
            setEmptyDataMessage(result.response.data.rows.length >= 1 ? null : 'Não há registros.')
            setStatusRequest(StatusRequest.SUCCESS);
          }
        })
    } catch {
      setStatusRequest(StatusRequest.ERROR);
    }
  };

  const nextPageAction = () => {
    fetchProviders('NEXT')
  };

  const lastPageAction = () => {
    fetchProviders('LAST');
  };

  const firstPageAction = () => {
    fetchProviders('START');
  };

  const prevPageAction = () => {
    fetchProviders('PREV')
  };

  useEffect(() => {
    if (!providers?.length && !emptyDataMessage) {
      fetchProviders('START');
    }
  }, [providers]);

  const updateCustomParamValue = (customParam: {
    [key: string]: string | number;
  }) => {
    setCustomParam(customParam);
  };

  return (
    <>
      <ModalLogout isOpen={showModalLogout} />
      <ContainerPage>
        <TitlePage title="Distribuidores" />
        <ProviderPageStyled.ContainerSubTitleText>
          <ProviderPageStyled.SubTitleText>
            Confira aqui a lista de Distribuidores participantes no Programa AGREGA.
          </ProviderPageStyled.SubTitleText>
        </ProviderPageStyled.ContainerSubTitleText>
        <SearchForm
          fetchProviders={fetchProviders}
          setCustomParam={updateCustomParamValue}
        />
        {emptyDataMessage ?
          <ProviderPageStyled.RowEmptyData>
            <p>{emptyDataMessage}</p>
          </ProviderPageStyled.RowEmptyData>
          : <Fragment />
        }
        <TableList
          data={{
            columnTitles: [
              "UF",
              "Distribuidor",
              "Cidade",
              "Bairro",
              "Logradouro",
              "Número",
            ],
            rows: providers?.map((p) => ({
              ["Bairro"]: p.neighborhood,
              ["Cidade"]: p.city,
              ["Distribuidor"]: p.name,
              ["Logradouro"]: p.address,
              ["Número"]: p.number,
              ["UF"]: p.state,
            })),
            totalItems: paginationProps.totalItems,
          }}
          fetching={{
            fetching: statusRequest === StatusRequest.FETCHING,
          }}
          error={{
            hasError: statusRequest === StatusRequest.ERROR,
          }}
          paginationActions={{
            firstPageAction: firstPageAction,
            lastPageAction: lastPageAction,
            nextPageAction: nextPageAction,
            prevPageAction: prevPageAction,
          }}
        />
      </ContainerPage>
    </>
  );
};

export default ProviderPage;
